import React from 'react';
import { formatDate } from '@arivaa-react/helpers/date-util';
export default {
  deleteAction: false,
  entityName: 'Account Type',
  entity: 'roles',
  pagination: {
    type: 'server',
  },
  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  search: {
    searchOnSubmit: true,
    filters: [
      {
        title: 'Role',
        type: 'input',
        key: 'code',
      },
    ],
  },
  displayType: 'card',
  role: 'admin',
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate('role.code.title'),
      dataIndex: 'code',
    },
    {
      title: translate('role.description.title'),
      dataIndex: 'description',
    },
    {
      title: translate('role.createdAt'),
      render: (createdAt) => {
        return <p>{formatDate(createdAt)}</p>;
      },
    },
  ],
  rowActions: [],
  formId: 'RolesForm',
};
