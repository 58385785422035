import React from 'react';
import './styles.scss';
import { Button, Form, Input } from 'antd';
import FilePicker from '@arivaa-react/components/filePicker';
import defaultPic from '../../../../images/beem/default-pic.png';
const FormItem = Form.Item;

/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { translate, form, auth } = this.props;
  const { passwordState } = this.state;
  const { getFieldDecorator } = form;
  this.setValidations();
  const { firstName, photoURL, lastName } = this.validations;
  return (
    <div className="setting user-information">
      <div className="user-form">
        <p className="label">General Settings</p>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <FormItem hasFeedback={true}>
            {getFieldDecorator(
              'photoURL',
              photoURL
            )(
              <FilePicker
                getChildren={({ getUri }) => {
                  return (
                    <img
                      src={getUri() || defaultPic}
                      className="profile-picker"
                    />
                  );
                }}
              />
            )}
          </FormItem>
          <FormItem hasFeedback={true} label="User Information">
            {getFieldDecorator(
              'name',
              firstName
            )(
              <Input
                type={'text'}
                placeholder={translate('common.name.title')}
                className="input"
              />
            )}
          </FormItem>
          <div className="action">
            <Button htmlType={'submit'} className="btn primary-btn">
              {translate('common.save.changes')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default view;
