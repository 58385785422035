import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import { Form, message } from 'antd';
import { createAction } from '@arivaa-react/redux';
import { CREATE_ENTITY, UPDATE_ENTITY } from 'app-redux/actions';

const spinningSelector = '.new-form';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.setValidations();
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { translate, data } = this.props;
    this.validations = {
      key: {
        rules: [
          {
            required: true,
            message: translate('sms.key.required'),
          },
          {
            pattern: /^[a-z0-9-_]+$/i,
            message:
              'Key can only be alphanumeric and can contain only hyphens (-)',
          },
        ],
        initialValue: data && data.key,
      },
      from: {
        // rules:[{
        //     required:true,
        //     message:translate('sms.from.required')
        // }],
        initialValue: data && data.from,
      },
      template: {
        rules: [
          {
            required: true,
            message: translate('sms.template.required'),
          },
        ],
        initialValue: data && data.template,
      },
      templateVariables: {
        rules: [],
        initialValue: data && data.templateVariables,
      },
    };
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {}

  handleSubmit(event) {
    event.preventDefault();
    const {
      form,
      translate,
      createRecord,
      hideModal,
      createStorage,
      updateRecord,
      data,
      getTableData,
    } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        if (data) {
          delete values.key;
          const { error, payload } = await updateRecord({
            entityId: data.key,
            ...values,
          });
          if (error) {
            throw payload.response;
          }
        } else {
          const { error, payload } = await createRecord(values);
          if (error) {
            throw payload.response;
          }
        }
        message.success(translate('common.changes.save.success'));
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
      } catch (e) {
        if (e && e.status === 404) {
          message.error(translate('common.changes.save.error'));
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    createRecord: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'sms-template',
          ...data,
        })
      );
    },
    updateRecord: ({ entityId, ...data }) => {
      return dispatch(
        createAction(UPDATE_ENTITY, {
          entityName: 'sms-template',
          entityId,
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = 'Media-Form';
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
