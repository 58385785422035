export const FREQUENCIES = [500, 1000, 2000, 3000, 4000, 6000, 8000].map(
  (value) => ({
    value,
    audio: require(`./audio/${value}.wav`),
  })
);

export const DEFAULT_FREQUENCY_INDEX = 1;
export const DEFAULT_VOLUME_DB = 15;
export const dbToVolume = (dbValue) => {
  return dbValue > 0 ? dbValue / 100 + 0.1 : 0;
};
export const MAX_DB = 90;

export const simulations = [
  {
    patternOfConfiguration: 'Low Frequency/Rising',
    right: {
      500: 30,
      1000: 20,
      2000: 10,
      3000: 10,
      4000: 5,
      6000: 10,
      8000: 10,
    },
    left: {
      500: 30,
      1000: 30,
      2000: 15,
      3000: 10,
      4000: 5,
      6000: 10,
      8000: 10,
    },
    randomizedResponses: {
      right: {
        500: 30,
        1000: 20,
      },
    },
  },
  {
    patternOfConfiguration: 'Asymetrical Loss',
    right: {
      500: 5,
      1000: 10,
      2000: 20,
      3000: 25,
      4000: 30,
      6000: 35,
      8000: 40,
    },
    left: {
      500: 10,
      1000: 10,
      2000: 25,
      3000: 40,
      4000: 50,
      6000: 90,
      8000: 90,
    },
  },
  {
    patternOfConfiguration: 'Noise-Induced Hearing Loss',
    right: {
      500: 10,
      1000: 10,
      2000: 15,
      3000: 20,
      4000: 40,
      6000: 30,
      8000: 20,
    },
    left: {
      500: 10,
      1000: 15,
      2000: 20,
      3000: 20,
      4000: 35,
      6000: 25,
      8000: 15,
    },
  },
  {
    patternOfConfiguration: 'High Frequency Hearing Loss',
    right: {
      500: 20,
      1000: 20,
      2000: 30,
      3000: 40,
      4000: 45,
      6000: 50,
      8000: 60,
    },
    left: {
      500: 25,
      1000: 30,
      2000: 25,
      3000: 40,
      4000: 50,
      6000: 55,
      8000: 65,
    },
    randomizedResponses: {
      right: {
        3000: 40,
        4000: 45,
      },
    },
  },
  {
    patternOfConfiguration: 'Unilateral Hearing Loss',
    right: {
      500: 0,
      1000: 5,
      2000: 10,
      3000: 15,
      4000: 10,
      6000: 10,
      8000: 5,
    },
    left: {
      500: 25,
      1000: 25,
      2000: 30,
      3000: 35,
      4000: 35,
      6000: 40,
      8000: 35,
    },
    randomizedResponses: {
      right: {
        1000: 5,
        3000: 15,
      },
    },
  },
  {
    patternOfConfiguration: 'Flat Hearing Loss',
    right: {
      500: 40,
      1000: 35,
      2000: 35,
      3000: 35,
      4000: 30,
      6000: 35,
      8000: 40,
    },
    left: {
      500: 35,
      1000: 30,
      2000: 35,
      3000: 35,
      4000: 35,
      6000: 40,
      8000: 35,
    },
  },
  {
    patternOfConfiguration: 'Normal Hearing',
    right: {
      500: 5,
      1000: 10,
      2000: 10,
      3000: 15,
      4000: 15,
      6000: 20,
      8000: 15,
    },
    left: {
      500: 0,
      1000: 5,
      2000: 10,
      3000: 10,
      4000: 15,
      6000: 15,
      8000: 10,
    },
  },
];
