import React from 'react';
import './styles.scss';
import { Row, Col, Icon } from 'antd';
import Link from '@arivaa-react/components/link';
import { currencyFormatter } from '../../../../helpers/currency';

var view = function () {
  const { dashboard, translate, user } = this.props;
  const { users, courseDirectors, revenue, licensesSold, expiringLicenses } =
    dashboard || {};
  let array = [];
  if (user.role === 'ADMIN') {
    array = [
      {
        count: users,
        label: translate('common.users'),
        route: 'home.users',
        icon: 'user',
      },
      {
        count: courseDirectors,
        label: 'Course Directors',
        route: 'home.course-director',
        icon: 'idcard',
      },
      {
        count: currencyFormatter(revenue),
        label: 'Net Revenue',
        route: 'home.user-plan',
        icon: 'dollar',
      },
      {
        count: licensesSold,
        label: 'Licenses Sold',
        route: 'home.user-plan',
        icon: 'appstore',
      },
    ];
  } else if (user.role === 'COURSEDIRECTOR') {
    array = [
      {
        count: users,
        label: translate('common.users'),
        route: 'home.users',
        icon: 'user',
      },
      ...(expiringLicenses
        ? [
            {
              count: expiringLicenses,
              label: 'Licenses Expiring in a week',
              route: 'home.user-plan',
              icon: 'appstore',
            },
          ]
        : []),
    ];
  } else {
    array = [];
  }
  return (
    <div className="dashboard">
      <div className="content">
        <p className="page-title mobile-only">{translate('dashboard.title')}</p>
        <Row gutter={16}>
          {array.map((item, index) => {
            const { count, label, route, icon } = item;
            return (
              <Col key={index} xs={24} sm={12} md={12} lg={6} xl={6}>
                <Link routeKey={route}>
                  <div className="dashboard-card">
                    <div className="info">
                      <div className="details">
                        <p className="count">{count || 0}</p>
                        <p className="label">{label}</p>
                      </div>
                      <div className="icon-cont">
                        <Icon type={icon} className="icon" />
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};
export default view;
