import React from 'react';
import LongText from '@arivaa-react/components/longtext';
export default {
  entityName: 'Enquiries',
  entity: 'contact',
  getRequestParams: () => {
    return {
      search: ['category;Enquiry'],
    };
  },
  role: 'admin',
  // deleteAction: false,
  pagination: {
    type: 'server',
  },
  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  selection: true,
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate('support.email.label'),
      dataIndex: 'email',
    },
    {
      title: translate('support.name.label'),
      dataIndex: 'name',
    },
    {
      title: translate('support.message.label'),
      dataIndex: 'message',
      render: (message) => {
        return <LongText text={message} limit={25} title="Message" />;
      },
    },
    {
      title: 'Page',
      dataIndex: 'page',
    },
    {
      title: 'Phone Number',
      dataIndex: 'otherInfo',
      render: (val) => {
        return val && val.phone ? val.phone : '';
      },
    },
  ],
  search: {
    filters: [
      {
        title: 'Name',
        type: 'input',
        key: 'name',
      },
      {
        title: 'Email',
        type: 'input',
        key: 'email',
      },
      {
        title: 'Message',
        type: 'input',
        key: 'message',
      },
      {
        title: 'Phone Number',
        type: 'input',
        key: 'otherInfo',
      },
    ],
    searchOnSubmit: true,
  },
};
