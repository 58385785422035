import React from 'react';
import './styles.scss';
import { Button, Checkbox, Form, Icon, Input, Select } from 'antd';
import FilePicker from '@arivaa-react/components/filePicker';
import PhoneInput from 'components/phone-input';

const CheckboxGroup = Checkbox.Group;
const FormItem = Form.Item;
const options = [
  { label: 'Give admin rights to this user', value: 'set-admin' },
];

function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

var view = function () {
  const { translate, form, hideModal, data, role: roleCode } = this.props;
  const { roles } = this.state;
  const { providerData } = data || {};
  const [provider] = providerData || [];
  const { providerId } = provider || {};
  const isSocial = providerId && providerId !== 'password';
  const { name, email, password, role, photoURL } = this.validations;
  const { getFieldDecorator } = form;

  return (
    <Form onSubmit={this.handleSubmit.bind(this)}>
      <div className="new-form user">
        <div className="form">
          <div className="content">
            <FormItem hasFeedback={true} label={'Profile Picture'}>
              <div className="image">
                {getFieldDecorator('photoURL', photoURL)(<FilePicker />)}
              </div>
            </FormItem>
            <div className="inline">
              <FormItem hasFeedback={true} label={translate('user.name.label')}>
                {getFieldDecorator(
                  'name',
                  name
                )(
                  <Input
                    maxLength={200}
                    type={'text'}
                    placeholder={translate('common.fullname.placeholder')}
                    className="input"
                  />
                )}
              </FormItem>
              {/*<FormItem hasFeedback={true} label={'Phone Number'}>
                {getFieldDecorator(
                  'phoneNumber',
                  this.validations.phoneNumber
                )(
                  <PhoneInput
                    country={window.app.DEFAULT_COUNTRY}
                    inputClass="input phone"
                  />
                )}
              </FormItem>*/}
            </div>
            {!isSocial && (
              <div className="inline">
                <FormItem
                  hasFeedback={true}
                  label={translate('user.email.label')}
                >
                  {getFieldDecorator(
                    'email',
                    email
                  )(
                    <Input
                      maxLength={100}
                      placeholder={translate('common.email.placeholder')}
                      className="input"
                    />
                  )}
                </FormItem>
                <FormItem
                  hasFeedback={true}
                  label={translate('user.password.label')}
                >
                  {getFieldDecorator(
                    'password',
                    password
                  )(
                    <Input
                      maxLength={200}
                      type={this.state.toggleVisible ? 'text' : 'password'}
                      prefix={
                        <a
                          onClick={() =>
                            this.setState({
                              toggleVisible: !this.state.toggleVisible,
                            })
                          }
                        >
                          <Icon
                            type={
                              this.state.toggleVisible ? 'eye-invisible' : 'eye'
                            }
                          />
                        </a>
                      }
                      placeholder={translate('common.password.placeholder')}
                      className="input"
                    />
                  )}
                </FormItem>
              </div>
            )}
          </div>

          {/*roleCode === 'USER' && (
            <>
              <div className="form-section">
                <p className="section-label">ID Verification</p>
                <div className="content">
                  <div className="inline">
                    <FormItem hasFeedback={true} label={'ID Proof'}>
                      <div className="image">
                        {getFieldDecorator(
                          'idProof',
                          this.validations.idProof
                        )(<FilePicker />)}
                      </div>
                    </FormItem>
                    <FormItem hasFeedback={true} label={'Document Proof'}>
                      <div className="image">
                        {getFieldDecorator(
                          'documentProof',
                          this.validations.documentProof
                        )(<FilePicker />)}
                      </div>
                    </FormItem>
                  </div>
                  <div className="inline">
                    <FormItem hasFeedback={true} label={'Username'}>
                      {getFieldDecorator(
                        'username',
                        this.validations.username
                      )(
                        <Input
                          maxLength={200}
                          placeholder={'Username'}
                          className="input"
                        />
                      )}
                    </FormItem>
                    <FormItem hasFeedback={true} label={'Home Address'}>
                      {getFieldDecorator(
                        'homeAddress',
                        this.validations.homeAddress
                      )(
                        <GooglePlacesAutocomplete
                          apiKey={window.app.MAPS_API_KEY}
                          className="input"
                          selectProps={{
                            placeholder: 'Select Address',
                          }}
                        />
                      )}
                    </FormItem>
                  </div>
                  <FormItem hasFeedback={true} label={'Bio'}>
                    {getFieldDecorator(
                      'bio',
                      this.validations.bio
                    )(<Input.TextArea placeholder={'Bio'} className="input" />)}
                  </FormItem>
                </div>
              </div>

              <div className="form-section">
                <p className="section-label">Optional Information</p>
                <div className="content">
                  <div className="inline">
                    <FormItem
                      hasFeedback={true}
                      label={'Secondary mobile number'}
                    >
                      {getFieldDecorator(
                        'secondaryPhoneNumber',
                        this.validations.secondaryPhoneNumber
                      )(
                        <PhoneInput
                          country={window.app.DEFAULT_COUNTRY}
                          inputClass="input phone"
                        />
                      )}
                    </FormItem>
                    <FormItem
                      hasFeedback={true}
                      label={'Alternative mobile number'}
                    >
                      {getFieldDecorator(
                        'alternativePhoneNumber',
                        this.validations.alternativePhoneNumber
                      )(
                        <PhoneInput
                          country={window.app.DEFAULT_COUNTRY}
                          inputClass="input phone"
                        />
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
              <div className="form-section">
                <p className="section-label">Add References</p>
                <div className="content">
                  {this.NUM_OF_REFERENCES.map((item, index) => {
                    const referenceName = `${ordinal_suffix_of(
                      index + 1
                    )} reference`;
                    const reference = data?.references?.[index];

                    return (
                      <React.Fragment key={index}>
                        <p className="label">{referenceName}</p>
                        <div className="inline">
                          <FormItem
                            hasFeedback={true}
                            label={translate('user.name.label')}
                          >
                            {getFieldDecorator('name' + index, {
                              initialValue: reference?.name,
                            })(
                              <Input
                                maxLength={200}
                                type={'text'}
                                placeholder={translate(
                                  'common.fullname.placeholder'
                                )}
                                className="input"
                              />
                            )}
                          </FormItem>
                          <FormItem
                            hasFeedback={true}
                            label={translate('user.email.label')}
                          >
                            {getFieldDecorator('email' + index, {
                              initialValue: reference?.email,
                            })(
                              <Input
                                maxLength={100}
                                placeholder={translate(
                                  'common.email.placeholder'
                                )}
                                className="input"
                              />
                            )}
                          </FormItem>
                          <FormItem hasFeedback={true} label={'Phone Number'}>
                            {getFieldDecorator('phoneNumber' + index, {
                              initialValue: reference?.phoneNumber,
                            })(
                              <PhoneInput
                                country={window.app.DEFAULT_COUNTRY}
                                inputClass="input phone"
                              />
                            )}
                          </FormItem>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </>
          )*/}
          {!roleCode && (
            <FormItem hasFeedback={true} label={translate('user.role.label')}>
              {getFieldDecorator(
                'role',
                role
              )(
                <Select className="select">
                  <Select.Option value={''}>Select Role</Select.Option>
                  {roles.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.code}>
                        {item.description}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
          )}
        </div>
        <div className="actions">
          <Button htmlType={'submit'} className="btn primary-btn save">
            {translate('user.save')}
          </Button>
          <Button
            htmlType={'button'}
            className="btn primary-btn-text cancel"
            onClick={hideModal}
          >
            {translate('user.cancel')}
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default view;
