import React from 'react';
import { formatDate } from '@arivaa-react/helpers/date-util';
import { currencyFormatter } from '../../../helpers/currency';
import { DatePicker } from 'antd';
export default {
  getRequestParams: () => {
    return {
      populate: true,
      search: [`status;A`],
    };
  },
  selection: false,
  entity: 'user-plan',
  entityName: 'Purchase History',
  deleteAction: false,
  addAction: false,
  editAction: false,
  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  pagination: {
    type: 'server',
  },
  search: (props) => {
    const { location, role } = props;
    const params = Object.fromEntries(new URLSearchParams(location.search));
    const { userEmail } = params;
    console.log({ role });
    return {
      searchOnSubmit: true,
      ...(userEmail && role === 'ADMIN'
        ? {
            isSubmitted: true,
            selectedFilter: 1,
            value: userEmail,
          }
        : {}),
      filters: [
        // {
        //   title: 'Purchased Date',
        //   key: 'createdAt',
        //   type: 'date',
        // },
        {
          title: 'Purchased Date',
          key: 'createdAt',
          type: 'custom',
          operator: 'between',
          element: DatePicker.RangePicker,
        },
        ...(role === 'ADMIN'
          ? [
              {
                title: 'User Email',
                key: 'userEmail',
                type: 'input',
                appendToQuery: true,
              },
            ]
          : []),
        {
          title: 'Expiry Date',
          key: 'expiry',
          type: 'custom',
          operator: 'between',
          element: DatePicker.RangePicker,
        },
      ],
    };
  },
  columns: ({ getTableData }) => [
    {
      title: 'User Name',
      dataIndex: 'user.displayName',
    },
    {
      title: 'User Email',
      dataIndex: 'user.email',
    },
    {
      title: 'Plan',
      dataIndex: 'plan.title',
    },
    {
      title: 'Price',
      dataIndex: 'plan.price',
      render: currencyFormatter,
    },
    {
      title: 'Total Licenses',
      dataIndex: 'totalSessions',
    },
    {
      title: 'Used Licenses',
      dataIndex: 'usedLicenses',
    },
    {
      title: 'Purchased Date',
      dataIndex: 'createdAt',
      render: (value) => formatDate(value, 'DD MMM YYYY'),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry',
      render: (value) => formatDate(value, 'DD MMM YYYY'),
    },
  ],
};
