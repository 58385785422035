import React, { useState } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Icon, InputNumber } from 'antd';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = (props) => {
  const {
    popular,
    user,
    price,
    description,
    title,
    sessions,
    onChoose,
    selected,
  } = props;
  const [count, setCount] = useState(sessions);
  const custom = sessions === 1 && user.role === 'COURSEDIRECTOR';
  return (
    <div className="plan-card-light">
      {popular ? <p className="popular">Popular</p> : null}
      {selected ? <p className="popular">Selected</p> : null}
      <div className="content">
        <p className="title">{sessions === 1 && !custom ? '' : title}</p>
        <p className="amount">
          <span className="currency">$</span>
          <span className="value">{custom ? price * count : price}</span>
        </p>

        {custom ? (
          <div className="custom">
            <p className="label">How many License you need ? </p>
            <InputNumber
              min={1}
              max={10}
              defaultValue={1}
              onChange={setCount}
            />
          </div>
        ) : (
          <p className="count">
            <span className="value">{sessions}</span> Audiometer License
            {`${sessions > 1 ? 's' : ''}`}
          </p>
        )}
        <div className="list">
          <ul>
            {(description || '').split('\n').map((item, index) => (
              <li key={index}>
                <Icon type="check-circle" theme="filled" />
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="actions">
          <Button
            className="primary-btn"
            onClick={() =>
              onChoose instanceof Function &&
              onChoose({
                ...props,
                amount: custom ? price * count : price,
              })
            }
          >
            Choose Plan
          </Button>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
Main.displayName = 'Sample-Container';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
