import React, { useEffect } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Form, message } from 'antd';
import Link from '@arivaa-react/components/link';
import { createAction } from '@arivaa-react/redux';
import {
  CREATE_ENTITY,
  GET_ENTITY,
  UI_SET_PROPERTY,
  UPDATE_ENTITY,
} from 'app-redux/actions';
import withLogin from '../with-login';
import wave from '../../images/beem/auth-wave.png';
import back from '../../images/beem/back.png';
import OtpInput from 'react-otp-input';
import { goBack, goToRoute } from '@arivaa-react/react-router5';
import Branding from '../../components/branding';

const spinningSelector = '.general-form.otp-verification';
const { Item } = Form;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({
  translate,
  form,
  verificationData,
  confirm,
  resend,
  setUiProperty,
}) => {
  const { getFieldDecorator, validateFieldsAndScroll } = form;
  const validations = {
    otp: {
      rules: [
        { required: true, message: 'Please enter the verification code' },
      ],
    },
  };

  useEffect(() => {
    if (!verificationData) {
      goToRoute('login');
    }
  }, []);

  /**
   * On Submit of  Form
   * @param event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        const response = await confirm({
          ...values,
          entityName: verificationData.api,
          entityId: verificationData.confirmationKey,
        });
        if (verificationData.onVerify instanceof Function) {
          verificationData.onVerify(response);
        }
      } catch (e) {
        console.error('Error while confirming otp ', { e });
        message.error(translate('verification.fail'));
      }
      window.stopSpinning(spinningSelector);
    });
  };

  /**
   * On Submit of  Form
   * @param event
   */
  const onResend = async (event) => {
    event.preventDefault();
    window.startSpinning(spinningSelector);
    try {
      const { payload } = await resend({
        entityName: verificationData.resendApi,
        entityId: verificationData.confirmationKey,
      });
      setUiProperty({
        name: 'verificationData',
        value: {
          ...verificationData,
          confirmationKey: payload.data.key,
        },
      });
      message.success(translate('verification.resend.success'));
    } catch (e) {
      console.error('Error while resending otp ', { e });
      message.error(translate('common.server.error.unexpected'));
    }
    window.stopSpinning(spinningSelector);
  };

  return (
    <div className="general-form otp-verification">
      <Link onClick={goBack} className="back">
        <img src={back} />
      </Link>
      <div className="content">
        <div className="form">
          <div className="fields">
            <p className="title">OTP Verification,</p>
            <p className="description">
              Please enter the verification code that we have sent to your{' '}
              {verificationData?.confirmationType}.
            </p>
            <Form onSubmit={handleSubmit}>
              <Item hasFeedback={true} className="otp-item">
                {getFieldDecorator(
                  'verificationCode',
                  validations.otp
                )(<OtpInput numInputs={4} inputStyle="input otp" />)}
              </Item>

              <div className="action">
                <Button htmlType={'submit'} className="btn primary-btn">
                  Verify
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <p className="option">
          Did't receive a code? <Link onClick={onResend}>Resend</Link>
        </p>
      </div>
      <Branding />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    setUiProperty: (data) => {
      return dispatch(createAction(UI_SET_PROPERTY, data));
    },
    confirm: (values) => {
      return dispatch(
        createAction(UPDATE_ENTITY, {
          ...values,
        })
      );
    },
    resend: (values) => {
      return dispatch(
        createAction(GET_ENTITY, {
          ...values,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ ui }) => {
  return {
    verificationData: ui.verificationData,
  };
};

Main.displayName = 'OTP-Verification-Form';
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
