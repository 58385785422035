import React from 'react';
import PhoneInput2 from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
export default React.forwardRef((props, ref) => {
  return (
    <PhoneInput2
      innerRef={ref}
      country={window?.app?.DEFAULT_COUNTRY}
      inputClass="input phone"
      {...props}
      value={props.value ? props.value : undefined}
    />
  );
});
