import React, { useState } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import {
  Button,
  Checkbox,
  Radio,
  Form,
  Icon,
  Input,
  Select,
  message,
} from 'antd';
import Link from '@arivaa-react/components/link';
import { createAction } from '@arivaa-react/redux';
import { REGISTER, UI_SET_PROPERTY } from 'app-redux/actions';
import withLogin from '../with-login';
import PhoneInput from 'components/phone-input';
import wave from '../../images/beem/auth-wave.png';
import { goToRoute } from '@arivaa-react/react-router5';
import Branding from '../../components/branding';

const spinningSelector = '.general-form.login';
const { Item } = Form;
const { Option } = Select;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({
  translate,
  form,
  ui,
  register,
  setUiProperty,
  isPhoneBased,
}) => {
  const { getFieldDecorator, validateFieldsAndScroll } = form;
  const validations = {
    name: {
      rules: [
        {
          required: true,
          message: translate('common.fullName.error.required'),
        },
      ],
      initialValue: window.app.demo ? 'Test User' : '',
    },
    email: {
      rules: [
        { required: true, message: translate('common.email.error.required') },
        { type: 'email', message: translate('common.email.error.invalid') },
      ],
      initialValue: window.app.demo ? 'idsingh43@gmail.com' : '',
    },
    phone: {
      rules: [
        { required: true, message: translate('common.phone.error.required') },
      ],
      initialValue: window.app.demo ? '918146660945' : '',
    },
    password: {
      rules: [
        {
          required: true,
          message: translate('common.password.error.required'),
        },
      ],
      initialValue: window.app.demo ? '123456' : '',
    },
    confirmPassword: {
      rules: [
        {
          required: true,
          message: translate('common.password.error.required'),
        },
      ],
      initialValue: window.app.demo ? '123456' : '',
    },
    rememberMe: {
      valuePropName: 'checked',
      rules: [
        {
          required: true,
          message: translate('common.terms.error.required'),
        },
      ],
    },
    role: {
      initialValue: 'USER',
    },
  };
  /**
   * toggleForm function switches between modals of join form
   * @param name
   */
  const toggleForm = (name) => {
    setUiProperty({
      name: 'activeTab',
      value: name,
    });
  };

  /**
   * On Submit of  Form
   * @param event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        values = { ...values };
        if (isPhoneBased) {
          values.phoneNumber = '+' + values.phoneNumber;
        }
        delete values.rememberMe;
        const { payload } = await register({
          ...values,
        });
        setUiProperty({
          name: 'verificationData',
          value: {
            confirmationKey: payload.data.confirmationKey,
            confirmationType: isPhoneBased ? 'phone number' : 'email',
            api: isPhoneBased ? 'confirm-phone' : 'confirm-email',
            resendApi: isPhoneBased
              ? 'confirm-phone/resend'
              : 'confirm-email/resend',
            onVerify: (response) => {
              message.success(translate('signUp.accountVerification.success'));
              goToRoute('login');
            },
          },
        });
        goToRoute('verify');
      } catch (e) {
        console.error('Error while sign up', { e });
        if (e.response.status === 409) {
          message.error(
            isPhoneBased
              ? translate('signUp.duplicate.phone')
              : translate('signUp.duplicate.email')
          );
        } else {
          message.error(translate('signUp.fail'));
        }
      }
      window.stopSpinning(spinningSelector);
    });
  };

  return (
    <div className="general-form login">
      <div className="content">
        <div className="form">
          <div className="fields">
            <p className="title">Welcome,</p>
            <p className="description">Sign up to continue!</p>
            <Form onSubmit={handleSubmit}>
              <Item hasFeedback={true}>
                {getFieldDecorator(
                  'name',
                  validations.name
                )(
                  <Input
                    placeholder={translate('common.fullname.placeholder')}
                    className="input"
                  />
                )}
              </Item>
              {isPhoneBased ? (
                <Item hasFeedback={true}>
                  {getFieldDecorator(
                    'phone',
                    validations.phone
                  )(<PhoneInput country={'in'} inputClass="input phone" />)}
                </Item>
              ) : (
                <Item hasFeedback={true}>
                  {getFieldDecorator(
                    'email',
                    validations.email
                  )(
                    <Input
                      placeholder={translate('common.email.placeholder')}
                      className="input"
                    />
                  )}
                </Item>
              )}
              <Item hasFeedback={true}>
                {getFieldDecorator(
                  'password',
                  validations.password
                )(
                  <Input
                    type={'password'}
                    placeholder={translate('common.password.placeholder')}
                    className="input"
                  />
                )}
              </Item>
              <Item hasFeedback={true} label="I am a">
                {getFieldDecorator(
                  'role',
                  validations.role
                )(
                  <Radio.Group>
                    <Radio value={'USER'}>Student</Radio>
                    <Radio value={'COURSEDIRECTOR'}>Course Director</Radio>
                  </Radio.Group>
                )}
              </Item>
              {/* <Item hasFeedback={true}>
                {getFieldDecorator(
                  'confirmPassword',
                  validations.confirmPassword
                )(
                  <Input
                    type={'password'}
                    placeholder={'Confirm password'}
                    className="input"
                  />
                )}
              </Item> */}
              <div className="more-links inline">
                <div className="remember">
                  <Item hasFeedback={true}>
                    {getFieldDecorator(
                      'rememberMe',
                      validations.rememberMe
                    )(
                      <Checkbox>
                        I accept all the{' '}
                        <Link routeKey="staticPages.terms" target="_blank">
                          terms & conditions.
                        </Link>
                      </Checkbox>
                    )}
                  </Item>
                </div>
              </div>
              <div className="action">
                <Button htmlType={'submit'} className="btn primary-btn">
                  Sign Up
                </Button>
              </div>
            </Form>
          </div>
          {/*<div className="separator">
            <span className="line" />
            <span>OR</span>
            <span className="line" />
          </div>
          <Link routeKey={isPhoneBased ? 'signup' : 'signup-phone'}>
            {isPhoneBased ? (
              <Button className="btn primary-btn-text">
                <span className="icon phone">
                  <Icon type="mail" />
                </span>
                Sign up with email
              </Button>
            ) : (
              <Button className="btn primary-btn-text">
                <span className="icon phone">
                  <Icon type="phone" />
                </span>
                Sign up with phone
              </Button>
            )}
          </Link>*/}
        </div>
        <p className="option">
          Already have an account? <Link routeKey={'login'}>Login</Link>
        </p>
      </div>
      <Branding />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    setUiProperty: (data) => {
      return dispatch(createAction(UI_SET_PROPERTY, data));
    },
    /**
     * Register API Action Creator
     * @param data
     */
    register: (data) => {
      return dispatch(
        createAction(REGISTER, {
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ ui }) => {
  return {
    ui,
  };
};

Main.displayName = 'Signup-Form';
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
