import React from 'react';
import './styles.scss';

/**
 * @description Forgot Password
 * @type Page
 * @author
 */
const Main = () => {
  return (
    <div className="brand">
      <a href="https://laxaar.com" target="_blank">
        <span>Maintained by</span>
        <img
          alt="Maintained by Laxaar"
          src="https://laxaar-email-assets.netlify.app/laxaar/dark-logo.png"
        />
      </a>
    </div>
  );
};

export default Main;
