import React from 'react';
import './styles.scss';
import { Icon, Layout, Menu } from 'antd';
import MenuKeys from './config';
import { isUserAllowed } from '../with-security';
import { getCurrentRoute, getRoute } from '@arivaa-react/react-router5';
import Stats from '../../components/stats';
import Branding from '../../components/branding';

const { SubMenu, Item, ItemGroup } = Menu;
const { Sider } = Layout;

var view = function () {
  const { permissions, drawer, role, translate, hasLicense } = this.props;
  const { routeKey } = getCurrentRoute();
  console.log(hasLicense);
  let menus = MenuKeys.filter((item) => {
    const routeProps = (getRoute(item.key) || {}).routeProps || {};

    return (
      role &&
      isUserAllowed(
        {
          ...routeProps,
        },
        {
          permissions,
          role,
        }
      ) &&
      (item.isAllowed instanceof Function
        ? !!item.isAllowed(permissions, role, hasLicense)
        : true)
    );
  });
  return role ? (
    <Sider
      collapsible={true}
      collapsed={!drawer}
      className="sider"
      breakpoint="md"
      width={240}
    >
      <Menu
        theme="light"
        mode="inline"
        ref={(ref) => {
          this.menuRef = ref;
        }}
        onClick={this.handleMenuClick}
        selectedKeys={menus
          .filter((item) => {
            return item.key === routeKey;
          })
          .map((item) => {
            return item.key;
          })}
      >
        {menus.map((item, index) => {
          const { icon, name, children, key, noTranslate } = item;

          if (children) {
            return (
              <SubMenu
                key={index}
                title={
                  <span>
                    <Icon type={icon} />
                    <span>{translate(name)}</span>
                  </span>
                }
              >
                {children.map((child, index) => {
                  const { icon, name, key, noTranslate } = child;
                  return (
                    <Item
                      key={key}
                      className={
                        routeKey === key ? 'ant-menu-item-selected' : {}
                      }
                    >
                      <Icon type={icon} />
                      <span>{noTranslate ? name : translate(name)}</span>
                    </Item>
                  );
                })}
              </SubMenu>
            );
          } else {
            return (
              <Item
                key={key}
                //className={(routeKey === key) ? 'ant-menu-item-selected' : ""}
              >
                <Icon type={icon} />
                <span>{noTranslate ? name : translate(name)}</span>
              </Item>
            );
          }
        })}
      </Menu>
      <Stats />
      <Branding />
    </Sider>
  ) : null;
};
export default view;
