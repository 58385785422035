import React from 'react';
import { Button, Tooltip, Icon, Switch } from 'antd';
import UpdateEntity from '../../containers/update-entity';
import AddDays from '../../containers/add-days';
import { ModalTrigger } from '@arivaa-react/components/modal';
import { formatDate } from '@arivaa-react/helpers/date-util';
import Link from '@arivaa-react/components/link';
export default {
  entityName: 'Users',
  entity: 'users',
  displayType: 'card',
  role: 'admin',
  pagination: {
    type: 'server',
  },
  selection: ({ uid }) => ({
    getCheckboxProps: (record) => ({
      disabled: record.uid === uid,
    }),
  }),
  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  shouldShowActions: ({ sharedState }) => {
    return (text, row, markup) => {
      const { uid } = sharedState;
      return uid === row.uid ? <p>You</p> : markup;
    };
  },
  reducer: {
    stateKeys: [
      {
        key: 'uid',
        fn: (state) => {
          return state.auth && state.auth.uid;
        },
      },
    ],
  },
  recordKey: 'uid',
  search: {
    filters: [
      {
        title: 'Name',
        type: 'input',
        key: 'displayName',
      },
      {
        title: 'Email Address',
        type: 'input',
        key: 'email',
      },
      /*{
        title: 'Phone Number',
        type: 'input',
        key: 'phoneNumber',
      },*/
    ],
    searchOnSubmit: true,
  },
  columns: ({ sharedState: { translate, role }, getTableData }) => [
    {
      title: translate('user.name.label'),
      dataIndex: 'displayName',
    },
    {
      title: translate('user.email.label'),
      dataIndex: 'email',
    },
    /*{
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },*/
    {
      title: translate('user.enabled'),
      dataIndex: 'disabled',
      render: (value, record) => {
        return (
          <UpdateEntity
            entityName="users"
            entityIdPropName="uid"
            record={record}
            getChildren={({ loading, updateEntity, record }) => {
              return record.role && record.role.toLowerCase() !== 'admin' ? (
                <Switch
                  loading={loading}
                  checked={!record.disabled}
                  onChange={async (checked) => {
                    await updateEntity({ disabled: !checked });
                    getTableData();
                  }}
                />
              ) : null;
            }}
          />
        );
      },
    },
    ...(role === 'admin'
      ? {
          title: 'Email Verified',
          dataIndex: 'emailVerified',
          render: (value, record) => {
            return (
              <UpdateEntity
                entityName="users"
                entityIdPropName="uid"
                record={record}
                getChildren={({ loading, updateEntity, record }) => {
                  return record.role &&
                    record.role.toLowerCase() !== 'admin' ? (
                    <Switch
                      loading={loading}
                      checked={record.emailVerified}
                      onChange={async (checked) => {
                        await updateEntity({ emailVerified: checked });
                        getTableData();
                      }}
                    />
                  ) : null;
                }}
              />
            );
          },
        }
      : []),
    // {
    //   title: 'Phone Verified',
    //   dataIndex: 'phoneVerified',
    //   render: (value, record) => {
    //     return (
    //       <UpdateEntity
    //         entityName="users"
    //         entityIdPropName="uid"
    //         record={record}
    //         getChildren={({ loading, updateEntity, record }) => {
    //           return record.role && record.role.toLowerCase() !== 'admin' ? (
    //             <Switch
    //               loading={loading}
    //               checked={record.phoneVerified}
    //               onChange={async (checked) => {
    //                 await updateEntity({ phoneVerified: checked });
    //                 getTableData();
    //               }}
    //             />
    //           ) : null;
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      title: translate('user.createdAt'),
      dataIndex: 'createdAt',
      render: (createdAt) => {
        return <p>{formatDate(createdAt)}</p>;
      },
    },
  ],
  rowActions: [
    ({ record, getTableData }) => {
      return <AddDays record={record} getTableData={getTableData} />;
    },
    ({ record, getTableData }) => {
      return (
        <Tooltip title={'Purchase History'}>
          <Link
            routeKey="home.user-plan"
            queryParams={{
              userEmail: record.email,
            }}
          >
            <Button className="btn blue-btn-text">
              <Icon type="dollar" />
            </Button>
          </Link>
        </Tooltip>
      );
    },
    //  ({record})=> record.userPlanId ? null : assignLicense
  ],
  actionsWidth: 230, // Width of actions column (which contains edit,delete button etc.
  formId: 'UsersForm',
  getRequestParams: () => {
    return {
      search: ['role;USER'],
    };
  },
};
