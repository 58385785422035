const redirectUri =
  typeof window !== 'undefined'
    ? window.location.origin + '/oauth2-callback'
    : '';
/**
 * Common configuration for each environment
 * @type {{}}
 */
export default {
  HOST: process.env.SERVER || 'http://20.42.119.110:8080',
  encryptionKey: process.env.ENCRYPTION_KEY || 'hor22kida',
  API_PREFIX: '/api/',
  reduxCacheExpiry: 60,
  socialLogin: {
    google: {
      key: 'GOOGLE_LOGIN_KEY',
      accessTokenUri: 'https://accounts.google.com/o/oauth2/token',
      authorizationUri: 'https://accounts.google.com/o/oauth2/auth',
      redirectUri,
      scopes: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      ],
      responseFormatter: ({ accessToken }) => {
        return {
          token: null,
          secret: accessToken,
          redirectUri,
        };
      },
    },
    facebook: {
      key: 'FACEBOOK_LOGIN_KEY',
      accessTokenUri: 'https://graph.facebook.com/v7.0/oauth/access_token',
      authorizationUri: 'https://www.facebook.com/v7.0/dialog/oauth',
      redirectUri,
      scopes: ['public_profile', 'email'],
    },
    linkedIn: {
      key: 'LINKEDIN_LOGIN_KEY',
      accessTokenUri: 'https://www.linkedin.com/oauth/v2/accessToken',
      authorizationUri: 'https://www.linkedin.com/oauth/v2/authorization',
      redirectUri,
      scopes: ['r_emailaddress', 'r_liteprofile'],
      query: {
        response_type: 'code',
      },
      responseFormatter: ({ data }) => {
        return {
          token: data.code,
          redirectUri,
        };
      },
    },
  },
};
