import React, { useEffect, useState } from 'react';
import preProcess from 'containers/preprocess';
import { Col, Row, message, Spin } from 'antd';
import './styles.scss';
import PlanCard from '../../../components/plan-card-light';
import {
  CREATE_ENTITY,
  GET_ENTITIES,
  GET_ENTITY,
  GET_PROFILE,
} from 'app-redux/actions';
import { createAction } from '@arivaa-react/redux';
import { PayPalButton } from 'react-paypal-button-v2';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ getPlans, translate, purchase, getProfile, user }) => {
  const [data, setData] = useState([]);
  const [purchaseData, setPurchaseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    window.startSpinning('body');
    try {
      const { payload } = await getPlans();
      setData(payload.data);
    } catch (e) {
      console.debug('Error while getting my plans', { e });
      message.error(translate('common.server.error.unexpected'));
    }
    window.stopSpinning('body');
  };
  const processPayment = async (details, data) => {
    console.log({ details, data });
    window.startSpinning('body');
    try {
      await purchase({
        orderID: data.orderID,
        payerID: data.payerID,
        planId: purchaseData.planId,
      });
      await getProfile();
      message.success('Your purchase was successful');
    } catch (e) {
      console.debug('Error while processing purchase', { e });
      message.error(translate('common.server.error.unexpected'));
    }
    window.stopSpinning('body');
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="plans">
      <Row gutter={24} align="top">
        {data
          .filter(
            ({ sessions }) => !(sessions > 1 && user.role !== 'COURSEDIRECTOR')
          )
          .map((plan, index) => (
            <Col xs={24} sm={24} md={12} lg={12} xl={6} key={index}>
              <PlanCard
                {...plan}
                planId={plan.key}
                onChoose={setPurchaseData}
                selected={purchaseData?.planId === plan.key}
              />
            </Col>
          ))}
      </Row>
      {data?.length > 0 && (
        <h4>
          Important note : All the plans listed above have an expiry date of 30
          days
        </h4>
      )}
      {purchaseData && (
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 12, offset: 6 }}
          >
            <PayPalButton
              onButtonReady={() => setLoading(false)}
              amount={purchaseData.amount}
              shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
              onSuccess={processPayment}
              options={{
                clientId: window.app.paypalClientId,
              }}
            />
          </Col>
        </Row>
      )}
      {purchaseData && loading ? (
        <Row gutter={24}>
          <Col offset={10}>
            <Spin spinning={true} size="large" />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getPlans: () => {
      return dispatch(
        createAction(GET_ENTITIES, {
          entityName: 'plan',
          sort: 'featured',
          sortType: 'desc',
          search: [`enabled;true;eq;boolean`],
          all: true,
        })
      );
    },
    purchase: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'user-plan/purchase',
          ...data,
        })
      );
    },
    getProfile: (data) => {
      return dispatch(createAction(GET_PROFILE, data));
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return { user };
};
Main.displayName = 'Plans';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
