import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import { Form, message } from 'antd';
import { getObjectsDiff } from '@arivaa-react/helpers/common';
import { createAction } from '@arivaa-react/redux';
import { CREATE_ENTITY, GET_ENTITIES, UPDATE_ENTITY } from 'app-redux/actions';
const regex = /^[A-Za-z0-9]+$/;
const spinningSelector = '.new-form';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.setValidations();
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { data, translate } = this.props;
    this.validations = {
      key: {
        rules: [
          {
            required: true,
            message: 'Code is Required',
          },
          {
            pattern: /^[a-z0-9-_]+$/i,
            message:
              'Key can only be alphanumeric and can contain only hyphens (-)',
          },
        ],
        initialValue: data ? data.key : null,
      },
      subject: {
        rules: [
          {
            required: true,
            message: 'Subject is required',
          },
        ],
        initialValue: data ? data.subject : null,
      },
      from: {
        rules: [
          //     {
          //     required: true,
          //     message: 'From Email is required'
          // },
          { type: 'email', message: translate('common.email.error.invalid') },
        ],
        initialValue: data ? data.from : null,
      },
      templateVariables: {
        rules: [],
        initialValue: data
          ? data.templateVariables
          : 'Variable name : Variable Value',
      },
      template: {
        rules: [
          {
            required: true,
            message: 'Template is Required',
          },
        ],
        initialValue: data ? data.template : null,
      },
      pdfTemplate: {
        initialValue: data ? data.pdfTemplate : null,
      },
    };
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {
    const { getRecords } = this.props;
    try {
      const { error, payload } = await getRecords();
      if (error) {
        throw payload.response;
      }
      const { data } = payload;
      this.setState({ data: data.data });
    } catch (e) {
      console.error('Error while fetching pdf templates', e);
      message.error('Error while fetching pdf templates');
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { validateFieldsAndScroll } = this.props.form;
    const {
      getTableData,
      hideModal,
      createRecord,
      updateRecord,
      data,
    } = this.props;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        if (data) {
          const { error, payload } = await updateRecord({
            entityId: data.key,
            ...getObjectsDiff(values, data),
          });
          if (error) {
            throw payload.response;
          }
        } else {
          const { error, payload } = await createRecord(values);
          if (error) {
            throw payload.response;
          }
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
      } catch (e) {
        console.log(e);
        if (e && e.status === 404) {
          message.error('Template with this code already Exist');
        } else {
          message.error('Error while Creating Email Template');
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getRecords: () => {
      return dispatch(
        createAction(
          GET_ENTITIES,
          {
            entityName: 'pdf-template',
            from: -1,
          },
          true
        )
      );
    },
    createRecord: (data) => {
      const action = createAction(CREATE_ENTITY, {
        entityName: 'email-template',
        ...data,
      });
      action.type = action.type + '_email-template';
      return dispatch(action);
    },
    updateRecord: ({ entityId, ...data }) => {
      const action = createAction(UPDATE_ENTITY, {
        entityName: 'email-template',
        entityId,
        ...data,
      });
      action.type = action.type + '_email-template';
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ config, ...state }) => {
  const { data } = state['pdf-template'] || {};
  return {
    templates: data,
  };
};
Main.displayName = 'User-Form';
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
