import React, { useEffect, useState } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import pointerImageLeft from '../../../images/audiometer/left-ear-pointer.png';
import pointerImageRight from '../../../images/audiometer/right-ear-pointer.png';
import { Line } from 'react-chartjs-2';
import {
  Button,
  Alert,
  Select,
  Icon,
  Tooltip,
  Form,
  Modal,
  notification,
  Switch,
} from 'antd';
import arrow from '../../../images/audiometer/arrow.png';
import moment from 'moment';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip as ChartTooltip,
} from 'chart.js';
import {
  dbToVolume,
  DEFAULT_FREQUENCY,
  DEFAULT_FREQUENCY_INDEX,
  DEFAULT_VOLUME_DB,
  FREQUENCIES,
  simulations,
  MAX_DB,
} from './data';
import { goToRoute } from '@arivaa-react/react-router5';
import { delay } from '@arivaa-react/helpers/common';
const labels = FREQUENCIES.map(({ value }) => value);
const options = {
  spanGaps: true,
  responsive: true,
  scales: {
    x: {
      position: 'top',
      title: {
        display: true,
        text: 'Frequency in Hertz (Hz)',
        font: {
          size: 14,
          weight: 500,
        },
      },
    },
    y: {
      min: -10,
      max: 110,
      reverse: true,
      ticks: {
        stepSize: 10,
      },
      title: {
        display: true,
        text: 'Hearing Level in Decibels (DB)',
        font: {
          size: 14,
          weight: 500,
        },
      },
    },
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

const defaultFrequencyValue = {
  left: DEFAULT_FREQUENCY_INDEX,
  right: DEFAULT_FREQUENCY_INDEX,
};

const defaultEar = 'left';
const getDefaultResults = () => ({
  left: new Array(FREQUENCIES.length),
  right: new Array(FREQUENCIES.length),
});

async function playPulsedAudio(audioElement) {
  const promise = new Promise((resolve, reject) => {
    let count = 1;
    audioElement.addEventListener('timeupdate', async () => {
      if (count === 3) {
        audioElement.pause();
        audioElement.currentTime = 0;
        resolve();
        return;
      }
      if (audioElement.currentTime > 0.1) {
        audioElement.pause();
        audioElement.currentTime = 0;
        await delay(0.1);
        audioElement.play();
        count++;
      }
    });
    audioElement.play();
  });

  return promise;
}

const leftEarPointer = () => {
  var pointer = new Image();
  pointer.src = pointerImageLeft;
  return pointer;
};

const rightEarPointer = () => {
  var pointer = new Image();
  pointer.src = pointerImageRight;
  return pointer;
};

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ hasLicense }) => {
  const [simulation, setSimulation] = useState(0);
  const [pulsed, setPulsed] = useState(false);
  const [frequencyIndex, setFrequencyIndex] = useState(defaultFrequencyValue);
  const [db, setDb] = useState({
    left: DEFAULT_VOLUME_DB,
    right: DEFAULT_VOLUME_DB,
  });
  const [ear, setEar] = useState(defaultEar);
  const [results, setResults] = useState(getDefaultResults());
  const [done, setDone] = useState(false);
  const [muted, setMuted] = useState(false);
  const frequency = FREQUENCIES[frequencyIndex[ear]];

  const onPresent = async () => {
    const audioElement = new Audio(frequency?.audio);
    audioElement.volume = muted || db[ear] === 90 ? 0 : dbToVolume(db[ear]);
    //audioElement.crossOrigin = "anonymous";
    /* const audioContext = new AudioContext();
    
    const audioSource = audioContext.createMediaElementSource(audioElement);

    const volumeNodeL = new GainNode(audioContext);
    const volumeNodeR = new GainNode(audioContext);

    volumeNodeL.gain.value = 2;
    volumeNodeR.gain.value = 2;

    const channelsCount = 2; // or read from: 'audioSource.channelCount'

    const splitterNode = new ChannelSplitterNode(audioContext, {
      numberOfOutputs: channelsCount,
    });
    const mergerNode = new ChannelMergerNode(audioContext, {
      numberOfInputs: channelsCount,
    });

    audioSource.connect(splitterNode);

    splitterNode.connect(volumeNodeL, 0); // connect OUTPUT channel 0
    splitterNode.connect(volumeNodeR, 1); // connect OUTPUT channel 1

    volumeNodeL.connect(mergerNode, 0, 0); // connect INPUT channel 0
    volumeNodeR.connect(mergerNode, 0, 1); // connect INPUT channel 1

    mergerNode.connect(audioContext.destination);
    if (ear === 'left') {
      volumeNodeL.gain.value = 2;//dbToVolume(db[ear]);
      volumeNodeR.gain.value = 0;
    } else {
      volumeNodeL.gain.value = 0;
      volumeNodeR.gain.value = 2;//dbToVolume(db[ear]);
    } */
    if (pulsed) {
      await playPulsedAudio(audioElement);
    } else {
      audioElement.play();
    }

    let simulatedValue = simulations[simulation][ear][frequency.value];
    /*const randomizedResponses =
    simulations[simulation]?.randomizedResponses?.[ear] || {};
    if (randomizedResponses?.[frequency.value]) {
      const randomValues = [simulatedValue, 90, db[ear] - 1];
      simulatedValue =
        randomValues[Math.floor(Math.random() * randomValues.length)];
            
    }*/

    if (simulatedValue === 90) {
      notification.error({
        message: db[ear] >= 90 ? 'No Response' : "Nopes, I didn't hear that",
      });
    } else if (db[ear] >= simulatedValue) {
      notification.success({
        message: 'Yay!, I heard that',
      });
    } else {
      notification.error({
        message: "Nopes, I didn't hear that",
      });
    }
  };

  const onFinalize = () => {
    const newResults = results[ear];
    newResults[frequencyIndex[ear]] = db[ear];
    setResults({
      ...results,
      [ear]: newResults,
    });
    notification.success({
      message: 'Threshold saved successfully',
    });
  };

  const toggleMute = () => {
    setMuted(!muted);
  };

  const onChangeVolume = (up) => {
    let newVolume = db[ear];
    if (up) {
      if (db[ear] < MAX_DB) {
        newVolume += 5;
      }
    } else {
      newVolume = db[ear] >= 10 ? db[ear] - 10 : 0;
    }
    setDb({
      ...db,
      [ear]: newVolume,
    });
  };

  const onDone = () => {
    for (let key of Object.keys(results)) {
      for (let value of results[key]) {
        if (value === undefined) {
          notification.error({
            message: `Please complete the evaluation of ${key} ear to submit results`,
          });
          return;
        }
      }
    }
    Modal.confirm({
      title: 'Confirm',
      content: 'Would you like to submit results?',
      onOk: () => setDone(true),
    });
    // notification.info({
    //   message : `Employee has ${simulations[simulation].patternOfConfiguration}`,
    //   duration : 100000
    // })
  };

  const reset = () => {
    setFrequencyIndex(defaultFrequencyValue);
    setEar(defaultEar);
    setResults(getDefaultResults());
    setDone(false);
    setPulsed(false);
  };

  useEffect(() => {
    setDb({
      ...db,
      [ear]: results[ear][frequencyIndex[ear]] || DEFAULT_VOLUME_DB,
    });
  }, [frequencyIndex]);
  useEffect(() => {
    if (!hasLicense) {
      notification.info({
        message: 'Please purchase a license to use simulator',
      });
      goToRoute('home.purchase');
    }
  }, []);

  const data = {
    labels,
    datasets: [
      {
        label: 'Left Ear',
        data: results['left'],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        pointHoverRadius: 8,
        pointStyle: (context) => {
          return context?.parsed?.y === 90 ? leftEarPointer() : 'crossRot';
        },
        pointRadius: 8,
        usePointStyle: true,
        borderWidth: 3,
        pointBorderColor: 'rgb(53, 162, 235,0.5)',
      },
      {
        label: 'Right Ear',
        data: results['right'],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        pointHoverRadius: 8,
        pointRadius: 8,
        pointStyle: (context) => {
          return context?.parsed?.y === 90 ? rightEarPointer() : undefined;
        },
      },
      ...(done
        ? [
            {
              label: 'Correct Left Ear',
              data: Object.values(simulations[simulation].left),
              borderColor: 'rgb(159, 9, 232)',
              backgroundColor: 'rgba(159, 9, 232, 0.5)',
              pointHoverRadius: 8,
              pointRadius: 8,
              pointStyle: (context) => {
                return context?.parsed?.y === 90
                  ? leftEarPointer()
                  : 'crossRot';
              },
              usePointStyle: true,
              borderWidth: 3,
              pointBorderColor: 'rgb(159, 9, 232,0.5)',
            },
            {
              label: 'Correct Right Ear',
              data: Object.values(simulations[simulation].right),
              borderColor: 'rgb(255, 118, 0)',
              backgroundColor: 'rgba(255, 118, 0, 0.5)',
              pointHoverRadius: 8,
              pointRadius: 8,
              pointStyle: (context) => {
                return context?.parsed?.y === 90
                  ? rightEarPointer()
                  : undefined;
              },
            },
          ]
        : []),
    ],
  };

  useEffect(() => {
    reset();
  }, [simulation]);
  return (
    <div className="simulator">
      <Form layout="inline">
        <Form.Item label="Select Employee">
          <Select
            size="large"
            onChange={(value) => {
              Modal.confirm({
                title: 'Are you sure?',
                content:
                  'Please note that Your changes will not be saved for this employee',
                onOk: () => setSimulation(value),
              });
            }}
            placeholder={'Select Employee'}
            value={simulation}
          >
            {simulations.map((simulation, index) => {
              return (
                <Select.Option key={index} value={index}>{`Employee - ${
                  index + 1
                }`}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Select Ear">
          <Select
            size="large"
            placeholder={'Select Ear'}
            value={ear}
            onChange={setEar}
          >
            <Select.Option value={'left'}>Left</Select.Option>
            <Select.Option value={'right'}>Right</Select.Option>
          </Select>
        </Form.Item>
        {!done && (
          <Form.Item>
            <Button className="primary-btn" onClick={onDone}>
              <Icon type="save" theme="filled" /> Submit Results
            </Button>
          </Form.Item>
        )}
        <Form.Item>
          <Button className="primary-btn-text" onClick={reset}>
            <Icon type="info-circle" theme="filled" /> Reset
          </Button>
        </Form.Item>
      </Form>
      <div className="chart">
        <Line options={options} data={data} />
      </div>
      {done && (
        <div className="controls">
          <div className="section center results">
            <div className="set">
              <p className="label">Pattern of configuration</p>
              <p className="value">
                {simulations[simulation].patternOfConfiguration}
              </p>
            </div>
          </div>
        </div>
      )}
      {!done && (
        <div className="controls">
          <div className="section center">
            <div className="set">
              <div className="left">
                <p className="label">Set Frequency</p>
                <p className="value">{frequency?.value} Hz</p>
              </div>
            </div>
            <div className="actions">
              <Tooltip placement="top" title={'Increase Frequency'}>
                <Button
                  onClick={() =>
                    frequencyIndex[ear] < FREQUENCIES.length - 1 &&
                    setFrequencyIndex({
                      ...frequencyIndex,
                      [ear]: frequencyIndex[ear] + 1,
                    })
                  }
                >
                  <img src={arrow} />
                </Button>
              </Tooltip>
              <Tooltip placement="top" title={'Decrease Frequency'}>
                <Button
                  onClick={() =>
                    frequencyIndex[ear] > 0 &&
                    setFrequencyIndex({
                      ...frequencyIndex,
                      [ear]: frequencyIndex[ear] - 1,
                    })
                  }
                >
                  <img src={arrow} className="down" />
                </Button>
              </Tooltip>
              <Button className="primary-btn" onClick={onFinalize}>
                <Icon type="check-circle" theme="filled" /> Save Threshold
              </Button>
            </div>
          </div>
          <div className="section right">
            <div className="set">
              <div className="left">
                <p className="label">Hearing Level</p>
                <p className="value">{db[ear]} dB</p>
              </div>
              <div className="right">
                Pulsed Tone&nbsp;&nbsp;
                <Switch onChange={(value) => setPulsed(value)} />
              </div>
            </div>
            <div className="actions">
              <Tooltip placement="top" title={'Increase Hearing Level'}>
                <Button onClick={() => onChangeVolume(true)}>
                  <img src={arrow} />
                </Button>
              </Tooltip>
              <Tooltip placement="top" title={'Decrease Hearing Level'}>
                <Button onClick={() => onChangeVolume(false)}>
                  <img src={arrow} className="down" />
                </Button>
              </Tooltip>
              <Button className="primary-btn" onClick={toggleMute}>
                <Icon type={muted ? 'stop' : 'sound'} theme="filled" />{' '}
                {muted ? 'Muted' : 'Mute'}
              </Button>
              <Button className="primary-btn" onClick={onPresent}>
                <Icon type={'thunderbolt'} theme="filled" /> Present
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return {
    hasLicense: !(
      user.currentLicense &&
      moment(user.currentLicense.expiry).isSameOrBefore(moment())
    ),
  };
};
Main.displayName = 'Sample-Container';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
