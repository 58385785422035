export default [
  {
    key: 'home',
    name: 'sider.dashboard',
    icon: 'appstore',
    isAllowed: (permissions, role) => role.toLowerCase() !== 'user',
  },
  {
    key: 'home.simulator',
    name: 'Simulator',
    icon: 'line-chart',
    noTranslate: true,

    isAllowed: (permissions, role, hasLicense) =>
      role.toLowerCase() === 'admin' || hasLicense,
  },
  {
    key: 'home.references',
    name: 'Simulator Reference',
    icon: 'fund',
    noTranslate: true,
    isAllowed: (permissions, role, hasLicense) =>
      role.toLowerCase() === 'admin' || (role.toLowerCase() === 'coursedirector' && hasLicense),
  },
  /*{
    key: 'home.permission',
    name: 'sider.permissions',
    icon: 'carry-out',
    role: 'admin',
  },*/
  {
    key: 'home.users',
    name: 'sider.users',
    icon: 'user',
    // isAllowed: (permissions, role) => role.toLowerCase() === 'admin',
  },
  {
    key: 'home.course-director',
    name: 'Course Directors',
    icon: 'idcard',
    noTranslate: true,
    // isAllowed: (permissions, role) => role.toLowerCase() === 'admin',
  },
  {
    key: 'home.plan',
    name: 'Plans',
    icon: 'appstore',
    noTranslate: true,
  },

  {
    // isAllowed: (permissions, role) => role.toLowerCase() === 'admin',
    key: 'home.plans',
    name: 'Purchase',
    icon: 'profile',
    noTranslate: true,
    isAllowed: (permissions, role) => role.toLowerCase() !== 'admin',
  },
  {
    key: 'home.user-plan',
    name: 'Purchase History',
    icon: 'dollar',
    // isAllowed: (permissions, role) => role.toLowerCase() === 'admin',
    noTranslate: true,
  },
  /*{
    key: 'home.sub-admins',
    name: 'Sub Admins',
    icon: 'team',
    // isAllowed: (permissions, role) => role.toLowerCase() === 'admin',
    noTranslate: true,
  },
  {
    key: 'home.roles',
    name: 'sider.roles',
    icon: 'idcard',
    role: 'admin',
  },*/

  {
    key: 'home.settings',
    name: 'sider.settings',
    icon: 'setting',
  },
];
