import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import logo from '../../images/audiometer/logo-white.png';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  return (
    <div className="auth">
      <div className="content">
        <img src={logo} className="logo" />
        <div className="text">
          <p className="title">Practicum Simulator</p>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = 'Sample-Container';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
