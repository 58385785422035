import Users from '../users';
export default {
  ...Users,
  getRequestParams: () => {
    return {
      search: ['role;COURSEDIRECTOR'],
    };
  },
  formId: 'CourseDirectorForm',
  entityName: 'Course Directors',
};
