import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import { Form, message } from 'antd';
import { getObjectsDiff } from '@arivaa-react/helpers/common';
import { createAction } from '@arivaa-react/redux';
import { CREATE_ENTITY, GET_ENTITIES, UPDATE_ENTITY } from 'app-redux/actions';

const spinningSelector = '.new-form';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      permissionsMap: {},
    };
    this.getRequiredPermissions = this.getRequiredPermissions.bind(this);
    this.setValidations();
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { translate, data } = this.props;
    this.validations = {
      code: {
        rules: [
          { required: !data, message: translate('role.code.error.required') },

          {
            pattern: /^[a-z0-9-_]+$/i,
            message:
              'Key can only be alphanumeric and can contain only hyphens (-)',
          },
        ],
        initialValue: data ? data.code : null,
      },
      description: {
        rules: [
          {
            required: true,
            message: translate('role.description.error.required'),
          },
        ],
        initialValue: data ? data.description : null,
      },
      permissions: {
        initialValue: data ? data.permissions : [],
      },
    };
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {
    const { getPermissions } = this.props;
    try {
      const { error, payload } = await getPermissions();
      if (error) {
        throw payload.response;
      }
      this.setState({
        permissions: payload.data.data,
        permissionsMap: this.getPermissionsMap(payload.data.data),
      });
    } catch (e) {
      console.log(e);
    }
  }

  getPermissionsMap(permissions) {
    let obj = {};
    permissions.forEach((permission) => {
      obj[permission.key] = permission;
    });
    return obj;
  }

  getRequiredPermissions(permissions) {
    permissions = permissions || [];
    if (permissions.length == 0) {
      return [];
    }
    let output = [...permissions];
    permissions.forEach((permission) => {
      permission = this.state.permissionsMap[permission] || {};
      let requiredPermissions = permission.requiredPermissions || [];
      output = [...output, ...this.getRequiredPermissions(requiredPermissions)];
    });

    return [...new Set(output)];
  }

  handleSubmit(event) {
    event.preventDefault();
    const { validateFieldsAndScroll } = this.props.form;
    const {
      translate,
      createRecord,
      hideModal,
      updateRecord,
      data,
      getTableData,
    } = this.props;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      // if (values.code.toLowerCase() === 'admin') {
      //   message.error(translate('role.code.error.exists'));
      //   return;
      // }
      window.startSpinning(spinningSelector);
      try {
        let action;
        let permissions = this.getRequiredPermissions(values.permissions);
        if (data) {
          let updatedValues = getObjectsDiff(values, data);
          action = await updateRecord({
            ...updatedValues,
            permissions,
            key: data.key,
          });
        } else {
          action = await createRecord(values);
        }
        if (!action.error) {
          message.success(translate('common.changes.save.success'));
          hideModal();
        } else {
          throw action.payload;
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
      } catch (err) {
        err = (err.response || {}).data || {};
        if (err.status && err.status === 409) {
          message.error(translate('role.code.error.exists'));
        } else {
          message.error(translate('common.changes.save.error'));
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getPermissions: () => {
      return dispatch(
        createAction(GET_ENTITIES, {
          entityName: 'permission',
          from: -1,
        })
      );
    },
    createRecord: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'roles',
          ...data,
        })
      );
    },
    updateRecord: ({ entityId, ...data }) => {
      const key = data.key;
      delete data.key;
      return dispatch(
        createAction(UPDATE_ENTITY, {
          entityName: 'roles',
          entityId: key,
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = 'User-Form';
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
