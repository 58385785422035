import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from '../preprocess';
import { Form, message } from 'antd';
import { createAction } from '@arivaa-react/redux';
import { goToRoute } from '@arivaa-react/react-router5';
import {
  CREATE_ENTITY,
  UI_SET_PROPERTY,
  UPDATE_ENTITY,
} from 'app-redux/actions';

const spinningSelector = '.general-form.forgetPassword';

/**
 * @description Login Form Container
 * @type Container
 * @author Jasjot
 */
class Main extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.setValidations();
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { translate } = this.props;
    this.validations = {
      email: {
        rules: [
          { required: true, message: translate('common.email.error.required') },
          { type: 'email', message: translate('common.email.error.invalid') },
        ],
        initialValue: window.app.demo ? 'idsingh43@gmail.com' : undefined,
      },
      phone: {
        rules: [
          { required: true, message: translate('common.phone.error.required') },
        ],
        initialValue: window.app.demo ? '918146660945' : undefined,
      },
    };
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {}

  /**
   * toggleForm function switches between modals of join form
   * @param value
   */
  toggleForm(value) {
    const { setUiProperty } = this.props;
    setUiProperty({
      name: 'activeTab',
      value,
    });
  }

  /**
   * On Submit of  Form
   * @param event
   */
  handleSubmit(event) {
    event.preventDefault();
    const { validateFieldsAndScroll, resetFields } = this.props.form;
    const {
      translate,
      passwordReset,
      ui,
      isPhoneBased,
      setVerificationData,
    } = this.props;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        if (isPhoneBased) {
          values.phoneNumber = '+' + values.phoneNumber;
        }
        const { error, payload } = await passwordReset({ ...values });
        if (error) {
          throw error;
        }
        const { key } = payload.data;
        resetFields();

        /**
         * If key is received that means its otp verification
         * scenario
         */
        if (key) {
          setVerificationData({
            confirmationKey: key,
            confirmationType: isPhoneBased ? 'phone number' : 'email',
            api: isPhoneBased
              ? 'password-reset-phone/verify'
              : 'password-reset/verify',
            resendApi: isPhoneBased
              ? 'password-reset-phone/resend'
              : 'password-reset/resend',
            onVerify: (response) => {
              setVerificationData({
                confirmationKey: key,
                api: isPhoneBased
                  ? 'password-reset-phone/confirm'
                  : 'password-reset/confirm',
                token: response.payload.data.token,
              });
              goToRoute('reset-password');
            },
          });
          goToRoute('verify');
        } else {
          message.success(translate('forgot.success'));
        }
      } catch (e) {
        console.debug('Error while resetting password ', { e });
        message.error(translate('forgot.error'));
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch, ownProps) => {
  return {
    setUiProperty: (data) => {
      return dispatch(createAction(UI_SET_PROPERTY, data));
    },
    passwordReset: (values) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: ownProps.isPhoneBased
            ? 'password-reset-phone'
            : 'password-reset',
          ...values,
        })
      );
    },
    setVerificationData: (verificationData) => {
      return dispatch(
        createAction(UI_SET_PROPERTY, {
          name: 'verificationData',
          value: verificationData,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ ui, emitter }) => {
  return {
    ui,
    emitter,
  };
};
Main.displayName = 'Forgot-Password-Form';
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
