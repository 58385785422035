import React from 'react';
import './styles.scss';
import { Button, Col, Form, Input, Row } from 'antd';
import Link from '@arivaa-react/components/link';
import AuthMedia from 'containers/authMedia';
import wave from '../../images/beem/auth-form-wave.png';
import back from '../../images/beem/back.png';

const FormItem = Form.Item;
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { translate, form, verificationData } = this.props;
  const { getFieldDecorator } = form;
  const { password, confirmPassword } = this.validations;
  return (
    <div>
      <Row>
        <Col xs={0} sm={0} md={0} lg={0} xl={12}>
          <AuthMedia />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div className="general-form resetPassword">
            <div className="content">
              <div className="form">
                <div className="fields">
                  <p className="title">
                    {translate('common.resetPassword.title')}
                  </p>
                  <p className="description">
                    {translate('common.resetPassword.subTitle')}
                  </p>
                  <Form
                    onSubmit={
                      verificationData
                        ? this.handleSubmitOtp.bind(this)
                        : this.handleSubmit.bind(this)
                    }
                  >
                    <FormItem hasFeedback={true}>
                      {getFieldDecorator(
                        'password',
                        password
                      )(
                        <Input
                          type={'password'}
                          placeholder={translate('common.password.placeholder')}
                          className="input"
                        />
                      )}
                    </FormItem>
                    <FormItem hasFeedback={true}>
                      {getFieldDecorator(
                        'confirmPassword',
                        confirmPassword
                      )(
                        <Input
                          type={'password'}
                          placeholder={translate(
                            'common.confirm-password.placeholder'
                          )}
                          className="input"
                        />
                      )}
                    </FormItem>
                    <div className="action">
                      <Button
                        htmlType={'submit'}
                        size="large"
                        className="btn primary-btn"
                      >
                        {translate('common.resetPassword.submit')}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default view;
