import React from 'react';
import './styles.scss';
import { Row, Col } from 'antd';
import AuthMedia from 'containers/authMedia';
import OTPVerification from 'containers/otpVerificationForm';

/**
 * @description Forgot Password
 * @type Page
 * @author
 */
const Main = () => {
  return (
    <div>
      <Row>
        <Col xs={0} sm={0} md={0} lg={0} xl={12}>
          <AuthMedia />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <OTPVerification />
        </Col>
      </Row>
    </div>
  );
};

Main.routeProps = {
  header: false,
  public: true,
  guestOnly: true,
};
export default Main;
