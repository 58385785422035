import React from 'react';
import DuplicateForm from '../../containers/duplicate-entity';
import UpdateEntity from '../../containers/update-entity';
import { Switch } from 'antd';
import { currencyFormatter, currencyParser } from '../../../helpers/currency';
export default {
  entity: 'plan',
  entityName: 'Plans',
  deleteAction: false,
  getRequestParams: () => {
    return {
      search: ['title;trial;ne'],
    };
  },
  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  pagination: {
    type: 'server',
  },
  search: {
    searchOnSubmit: true,
    filters: [
      {
        title: 'Title',
        key: 'title',
        type: 'input',
      },
    ],
  },
  columns: ({ getTableData }) => [
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: currencyFormatter,
    },
    {
      title: 'Number of Licenses',
      dataIndex: 'sessions',
    },
    {
      title: 'Popular',
      dataIndex: 'popular',
      render: (value, record) => {
        return (
          <UpdateEntity
            entityName="plan"
            record={record}
            getChildren={({ loading, updateEntity, record }) => {
              return (
                <Switch
                  loading={loading}
                  checked={record.popular}
                  onChange={async (checked) => {
                    await updateEntity({ popular: checked });
                    getTableData();
                  }}
                />
              );
            }}
          />
        );
      },
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      render: (value, record) => {
        return (
          <UpdateEntity
            entityName="plan"
            record={record}
            getChildren={({ loading, updateEntity, record }) => {
              return (
                <Switch
                  loading={loading}
                  checked={record.enabled}
                  onChange={async (checked) => {
                    await updateEntity({ enabled: checked });
                    getTableData();
                  }}
                />
              );
            }}
          />
        );
      },
    },
  ],
  form: {
    skipTranslate: true,
    elements: [
      {
        type: 'text',
        name: 'title',
        label: 'Title',
        required: true,
        inputProps: {
          maxLength: 200,
        },
      },
      {
        type: 'textarea',
        name: 'description',
        label: 'Description',
        inputProps: {
          maxLength: 200,
        },
        required: true,
      },
      {
        type: 'number',
        name: 'price',
        label: 'Price (USD)',
        required: true,
        inputProps: {
          precision: 2,
          // formatter: currencyFormatter,
          // parser: currencyParser,
        },
      },
      {
        type: 'number',
        name: 'sessions',
        label: 'Number of Licenses',
        required: true,
        inputProps: {
          max: 100,
          min: 1,
          precision: 0,
        },
      },
    ],
  },
  rowActions: [
    (props) => {
      const { record, getData } = props;
      const { key } = record;
      return (
        <DuplicateForm
          entityName="plan"
          entityId={key}
          propName="title"
          getData={getData}
        />
      );
    },
  ],
};
