import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import moment from 'moment';
import Link from '@arivaa-react/components/link';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ user }) => {
  const expired =
    user.currentLicense &&
    moment(user.currentLicense.expiry).isSameOrBefore(moment());
  return (
    user.role !== 'ADMIN' && (
      <div className="stats">
        {user.currentLicense ? (
          <div className="item expiring">
            <p className="text">
              Current license {expired ? 'expired' : 'expiring'} on
            </p>
            <p className="value">
              {moment(user.currentLicense.expiry).format('DD-MMM-YYYY')}
            </p>
          </div>
        ) : (
          <div className="item expiring">
            <p className="text">No Current License</p>
            <p className="value">
              <Link routeKey="home.plans">Purchase</Link>
            </p>
          </div>
        )}
        {user.role === 'COURSEDIRECTOR' && (
          <>
            <div className="item">
              <p className="text">Unused Licenses Left</p>
              <p className="value">
                {user.totalSessions - (user.usedSessions || 0)}
              </p>
            </div>
            <div className="item">
              <p className="text">Licenses Used</p>
              <p className="value">{user.usedSessions || 0}</p>
            </div>
          </>
        )}
      </div>
    )
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
Main.displayName = 'Sample-Container';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
