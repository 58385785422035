import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import { Form, message } from 'antd';
import { goToRoute } from '@arivaa-react/react-router5';
import { createAction } from '@arivaa-react/redux';
import { CREATE_ENTITY, GET_ENTITY, UPDATE_ENTITY } from 'app-redux/actions';

const spinningSelector = '.main-container';

/**
 * @description Login Form Container
 * @type Container
 * @author Jasjot
 */
class Main extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.setValidations();
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { translate } = this.props;
    this.validations = {
      password: {
        rules: [
          {
            required: true,
            message: translate('common.password.error.required'),
          },
          { min: 6, message: translate('common.password.error.invalid') },
        ],
      },
      confirmPassword: {
        rules: [
          {
            required: true,
            message: translate('common.confirmPassword.error.required'),
          },
          { validator: this.checkPassword.bind(this), name: 'confirmPassword' },
        ],
      },
    };
  }

  /**
   * Antd Validator for checking both passwords
   * @param rule
   * @param value
   * @param callback
   */
  checkPassword(rule, value, callback) {
    const { getFieldValue } = this.props.form;
    const { translate } = this.props;
    if (value !== '' && value !== getFieldValue('password')) {
      callback(translate('common.error.password.match'));
    } else {
      callback();
    }
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {
    const { verificationData } = this.props;

    /**
     * verificationData - OTP Based
     */
    if (!verificationData) {
      goToRoute('login');
      // Uncomment for link based
      /*const { translate, location, validateToken } = this.props;
      const { search } = location || {};
      const token = new URLSearchParams(search).get('token');
      window.startSpinning(spinningSelector);
      try {
        const { error } = await validateToken(token);
        if (error) {
          throw error;
        }
      } catch (e) {
        message.error(translate('resetPassword.error.invalidLink'));
        goToRoute('login');
      }
      window.stopSpinning(spinningSelector);*/
    }
  }

  /**
   * On Submit of  Form
   * @param event
   */
  handleSubmit(event) {
    event.preventDefault();
    const { validateFieldsAndScroll, resetFields } = this.props.form;
    const { translate, location, resetPassword } = this.props;
    const { search } = location || {};
    const token = new URLSearchParams(search).get('token');
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        if (token) {
          const { password } = values;
          const { error, payload } = await resetPassword({
            token,
            password,
          });
          if (error) {
            throw payload.response.data;
          }
          resetFields();
          message.success(translate('resetPassword.success'));
          goToRoute('login');
        } else {
          message.error(translate('resetPassword.error.invalidLink'));
        }
      } catch (e) {
        if (e.message) {
          message.error(e.message);
        } else {
          message.error(translate('resetPassword.error.server'));
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  handleSubmitOtp(event) {
    event.preventDefault();
    const { validateFieldsAndScroll, resetFields } = this.props.form;
    const { translate, resetPasswordOtp, verificationData } = this.props;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        console.log({ verificationData });
        const { password } = values;
        const { error, payload } = await resetPasswordOtp({
          password,
          token: verificationData.token,
          key: verificationData.confirmationKey,
          entityName: verificationData.api,
        });
        if (error) {
          throw payload.response.data;
        }
        resetFields();
        message.success(translate('resetPassword.success'));
        goToRoute('login');
      } catch (e) {
        if (e.message) {
          message.error(e.message);
        } else {
          message.error(translate('resetPassword.error.server'));
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    resetPassword: ({ token, password }) => {
      return dispatch(
        createAction(UPDATE_ENTITY, {
          entityName: 'password-reset',
          entityId: token,
          password,
        })
      );
    },
    resetPasswordOtp: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          ...data,
        })
      );
    },
    validateToken: (token) => {
      return dispatch(
        createAction(GET_ENTITY, {
          entityName: 'password-reset',
          entityId: token,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ emitter, ui }) => {
  return {
    emitter,
    verificationData: ui.verificationData,
  };
};
Main.displayName = 'Reset-Password-Form';
//Pre process the container with Redux Plugins
const Page = preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});

Page.routeProps = {
  header: false,
  public: true,
  guestOnly: true,
};
export default Page;
