export default {
  entity: 'permission',
  entityName: 'Permissions',
  formId: 'PermissionForm',
  pagination: {
    type: 'server',
  },
  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate('permission.key.label'),
      dataIndex: 'key',
    },
    {
      title: translate('permission.description.label'),
      dataIndex: 'description',
    },
  ],
  search: {
    searchOnSubmit: true,
    filters: [
      {
        type: 'input',
        title: 'Key',
        key: 'key',
      },
    ],
  },
};
