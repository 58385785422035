import { delay } from '@arivaa-react/helpers/common';
import { LOGIN, LOGOUT, UI_SET_PROPERTY } from 'app-redux/actions';
import React, { useEffect } from 'react';
import { message } from 'antd';
import preprocess from './preprocess';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { goToRoute } from '@arivaa-react/react-router5';
import { AUTH_REMEMBER_ME_KEY } from 'utils/security';
export default (Main, config) => {
  config = config || {};
  let App = (props) => {
    const { login, translate, emitter, logout, setUiProperty } = props;
    useEffect(() => {
      if (props.auth) {
        logout();
        goToRoute('login');
      }
    }, []);
    const callLogin = async ({ rememberMe, ...values }) => {
      let loggedIn = false;
      let isDisabled = false;
      let response;
      config.spinningSelector && window.startSpinning(config.spinningSelector);
      try {
        window.localStorage.setItem(AUTH_REMEMBER_ME_KEY, rememberMe !== false);
        response = await login(values);
        loggedIn = true;
      } catch (e) {
        console.error('Error while logging in ', { e });
        if (
          e?.response?.status === 403 &&
          e?.response?.data?.details?.code === 'EMAIL_NOT_VERIFIED'
        ) {
          const isPhoneBased = values.phone;
          setUiProperty({
            name: 'verificationData',
            value: {
              confirmationKey: e?.response?.data?.details?.confirmationKey,
              confirmationType: isPhoneBased ? 'phone number' : 'email',
              api: isPhoneBased ? 'confirm-phone' : 'confirm-email',
              resendApi: isPhoneBased
                ? 'confirm-phone/resend'
                : 'confirm-email/resend',
              onVerify: (response) => {
                message.success(
                  translate('signUp.accountVerification.success')
                );
                goToRoute('login');
              },
            },
          });
          goToRoute('verify');
          config.spinningSelector &&
            window.stopSpinning(config.spinningSelector);
          return;
        } else if (e?.response?.status === 403) {
          isDisabled = true;
        }
      }
      if (loggedIn) {
        emitter.emit('AUTHENTICATED', {
          status: true,
          type: 'LOGIN',
        });
        await delay(1);
        if (props.auth && props.auth.emailVerified) {
          message.success(translate('login.success'));
        }
      } else {
        if (isDisabled) {
          message.error(translate('login.disabled'));
        } else {
          message.error(response?.data?.message || translate('login.invalid'));
        }
      }
      config.spinningSelector && window.stopSpinning(config.spinningSelector);
      return response;
    };
    return <Main {...props} login={callLogin} />;
  };
  /**
   * Bind State to props
   * @param dispatch
   * @returns {{Object}}
   */
  const mapStateToProps = ({ auth, emitter }) => {
    return {
      auth,
      emitter,
    };
  };
  /**
   * Bind Redux Actions
   * @param dispatch
   * @returns {{Object}}
   */
  const bindAction = (dispatch) => {
    return {
      setUiProperty: (data) => {
        return dispatch(createAction(UI_SET_PROPERTY, data));
      },
      login: (data) => {
        return dispatch(
          createAction(LOGIN, {
            ...data,
          })
        );
      },
      logout: () => {
        return dispatch(createAction(LOGOUT));
      },
    };
  };
  App = preprocess(App, {
    connect: [mapStateToProps, bindAction],
    localize: true,
  }); //connect(mapStateToProps, bindAction)(App);
  return App;
};
