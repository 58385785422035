import { parsePhoneNumber } from 'libphonenumber-js';
export function parsePhone(phone) {
  try {
    const parsed = parsePhoneNumber(phone);
    if (parsed) {
      parsed.countryCallingCode = '+' + parsed.countryCallingCode;
    }
    return parsed;
  } catch (e) {
    console.debug('error while parsing phone number', { e });
  }
  return null;
}
