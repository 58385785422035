import React, { useState } from 'react';
import preProcess from 'containers/preprocess';
import { Button, message, Icon, Tooltip } from 'antd';
import { createAction } from '@arivaa-react/redux';
import { ConfirmForm } from '@arivaa-react/components/confirmInput';
import ModalTrigger from '@arivaa-react/components/modal/modal-trigger';

import { CREATE_ENTITY } from 'app-redux/actions';

/**
 * @description Refresh Index
 * @type Container
 * @author Inderdeep
 */
const Main = function ({ apiRequest, record, isAdmin }) {
  const spinningSelector = '.duplicate-form';
  const onSubmit = async (form) => {
    try {
      const { value, hideModal } = form || {};
      window.startSpinning(spinningSelector);
      const { payload } = await apiRequest({
        days: value,
        userId: record.key,
      });

      if (payload.status === 200) {
        message.success('Request processed successfully');
      } else {
        throw payload;
      }
      window.stopSpinning(spinningSelector);
      if (hideModal instanceof Function) {
        hideModal();
      }
    } catch (e) {
      console.error('Error while initiating your request', e);
      message.error('Error while initiating your request');
      window.stopSpinning(spinningSelector);
    }
  };
  const confirmConfig = {
    inputLabel: 'Enter number of days',
    placeholder: 'Number of days for trial',
    inputType: 'number',
    inputProps: {
      min: 1,
      max: 300,
    },
    onSubmit: ({ value, ...values }, { hideModal }) => {
      return onSubmit({ value, values, hideModal });
    },
  };
  const trigger = (
    <Tooltip title="Add Trial Days">
      <Button className="btn yellow-btn-text">
        <Icon type="plus" theme="outlined" />
      </Button>
    </Tooltip>
  );
  return (
    isAdmin && (
      <ModalTrigger
        content={<ConfirmForm {...confirmConfig} />}
        modalProps={{
          title: 'Add Trial Days',
          footer: null,
          className: 'duplicate-form',
        }}
      >
        {trigger}
      </ModalTrigger>
    )
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    apiRequest: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'user-plan/addTrial',
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return {
    isAdmin: user?.role === 'ADMIN',
  };
};
Main.displayName = 'Add-Days';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
