import React from 'react';
import './styles.scss';
import { Input, Button, Form, Icon } from 'antd';
import Link from '@arivaa-react/components/link';
import back from 'images/beem/back.png';
import PhoneInput from 'components/phone-input';
import Branding from '../../components/branding';
const FormItem = Form.Item;
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { translate, form, isPhoneBased } = this.props;
  const { getFieldDecorator } = form;
  const { email, phone } = this.validations;
  return (
    <div className="general-form forgetPassword">
      <Link routeKey={'login'} className="back">
        <img src={back} />
      </Link>
      <div className="content">
        <div className="form">
          <div className="fields">
            <p className="title">{translate('forgot.title')}</p>
            <p className="description">
              Please enter your {isPhoneBased ? 'phone number' : 'email'} to
              receive reset link
            </p>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              {isPhoneBased ? (
                <FormItem hasFeedback={true}>
                  {getFieldDecorator(
                    'phoneNumber',
                    phone
                  )(<PhoneInput inputClass="input phone" country={'in'} />)}
                </FormItem>
              ) : (
                <FormItem hasFeedback={true}>
                  {getFieldDecorator(
                    'email',
                    email
                  )(
                    <Input
                      placeholder={translate('common.email.placeholder')}
                      className="input"
                    />
                  )}
                </FormItem>
              )}

              <div className="action">
                <Button htmlType={'submit'} className="btn primary-btn">
                  {translate('forgot.submit')}
                </Button>
              </div>
            </Form>
          </div>
          {/*<div className="separator">
            <span className="line" />
            <span>OR</span>
            <span className="line" />
          </div>
          <Link routeKey={isPhoneBased ? 'forgot' : 'forgot-phone'}>
            {isPhoneBased ? (
              <Button className="btn primary-btn-text">
                <span className="icon phone">
                  <Icon type="mail" />
                </span>
                Reset with email
              </Button>
            ) : (
              <Button className="btn primary-btn-text">
                <span className="icon phone">
                  <Icon type="phone" />
                </span>
                Reset with phone
              </Button>
            )}
          </Link>*/}
        </div>
      </div>
      <Branding />
    </div>
  );
};
export default view;
