import React from 'react';
import Print from '../../containers/print';
import DuplicateForm from '../../containers/duplicate-entity';
export default {
  entity: 'pdf-template',
  selection: false,
  addAction: false,
  rowActions: [
    (props) => {
      const { record, getData } = props;
      const { key } = record;
      return (
        <DuplicateForm
          entityName="pdf-template"
          entityId={key}
          getData={getData}
        />
      );
    },
    (props) => {
      const { record } = props;
      const { key } = record;
      return <Print template={key} data={{}} />;
    },
  ],
  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  deleteAction: false,
  entityName: 'PDF Templates',
  formId: 'PdfTemplateForm',
  pagination: {
    type: 'server',
  },
  search: {
    filters: [
      {
        title: 'Name',
        type: 'input',
        key: 'name',
      },
    ],
    searchOnSubmit: true,
  },
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate('pdfTemplate.name.label'),
      dataIndex: 'name',
    },
  ],
};
