import React from 'react';
import './styles.scss';
import { Form, Input, Button, Row, Col, Icon } from 'antd';

import Editor from 'components/codeEditor';

const { Item } = Form;
const { TextArea } = Input;

var view = function () {
  const { form, hideModal, data, translate } = this.props;
  const { name, template, key, templateVariables } = this.validations;
  const { getFieldDecorator, getFieldValue } = form;
  const templateValue = getFieldValue('template') || (data && data.template);
  return (
    <div className="new-form form pdf-template-form">
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="build">
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <div className="inline">
                <Item
                  hasFeedback={true}
                  label={translate('pdfTemplate.key.label')}
                >
                  {getFieldDecorator(
                    'key',
                    key
                  )(
                    <Input
                      addonBefore={<Icon type="edit" />}
                      maxLength={100}
                      disabled={!!data}
                      className="input uppercase"
                      placeholder={translate('pdfTemplate.key.placeholder')}
                    />
                  )}
                </Item>
                <Item
                  hasFeedback={true}
                  label={translate('pdfTemplate.name.label')}
                >
                  {getFieldDecorator(
                    'name',
                    name
                  )(
                    <Input
                      addonBefore={<Icon type="edit" />}
                      maxLength={200}
                      className="input"
                      placeholder={translate('pdfTemplate.name.placeholder')}
                    />
                  )}
                </Item>
              </div>
              <div className="variables">
                <Item
                  hasFeedback={true}
                  label={translate('pdfTemplate.templateVariables.label')}
                >
                  {getFieldDecorator(
                    'templateVariables',
                    templateVariables
                  )(
                    <TextArea
                      maxLength={2000}
                      placeholder={translate(
                        'pdfTemplate.templateVariables.placeholder'
                      )}
                      rows={4}
                      style={{ resize: 'none' }}
                    />
                  )}
                </Item>
              </div>
              <div className="editor">
                <Item
                  hasFeedback={true}
                  label={translate('pdfTemplate.template.label')}
                >
                  {getFieldDecorator('template', template)(<Editor />)}
                </Item>
              </div>
              <div className="actions">
                <Button htmlType={'submit'} className="btn green-btn save">
                  {translate('pdfTemplate.save')}
                </Button>
                <Button
                  htmlType={'button'}
                  className="btn cancel"
                  onClick={hideModal}
                >
                  {translate('pdfTemplate.cancel')}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
        <Col xs={0} sm={0} md={12} lg={12} xl={12}>
          <div className="preview">
            <p className="title">{translate('pdfTemplate.preview')}</p>
            <div className="content">
              <iframe className="iframe" srcDoc={templateValue} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default view;
