import React, { useState } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Checkbox, Form, Icon, Input, Select } from 'antd';
import Link from '@arivaa-react/components/link';
import { createAction } from '@arivaa-react/redux';
import { UI_SET_PROPERTY } from 'app-redux/actions';
import withLogin from '../with-login';
import PhoneInput from 'components/phone-input';
import Branding from '../../components/branding';

const spinningSelector = '.general-form.login';
const { Item } = Form;
const { Option } = Select;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ translate, form, ui, login, setUiProperty, isPhoneBased }) => {
  const { getFieldDecorator, validateFieldsAndScroll } = form;
  const [showPassword, setShowPassword] = useState(false);
  const validations = {
    email: {
      rules: [
        { required: true, message: translate('common.email.error.required') },
        { type: 'email', message: translate('common.email.error.invalid') },
      ],
      initialValue: window.app.demo ? 'idsingh43+23@gmail.com' : undefined,
    },
    phone: {
      rules: [
        { required: true, message: translate('common.phone.error.required') },
      ],
      initialValue: window.app.demo ? '918146660945' : undefined,
    },
    password: {
      rules: [
        {
          required: true,
          message: translate('common.password.error.required'),
        },
      ],
      initialValue: window.app.demo ? '123456' : undefined,
    },
    rememberMe: {
      initialValue: true,
      valuePropName: 'checked',
    },
  };
  /**
   * toggleForm function switches between modals of join form
   * @param name
   */
  const toggleForm = (name) => {
    setUiProperty({
      name: 'activeTab',
      value: name,
    });
  };

  /**
   * On Submit of  Form
   * @param event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      values = { ...values };
      if (isPhoneBased) {
        values.phone = '+' + values.phone;
      }
      login({
        ...values,
        rememberMe: !!values.rememberMe,
        type: isPhoneBased ? 'phone' : 'local',
      });
    });
  };

  return (
    <div className="general-form login">
      <div className="content">
        <div className="form">
          <div className="fields">
            <p className="title">Welcome,</p>
            <p className="description">Log in to continue!</p>
            <Form onSubmit={handleSubmit}>
              {isPhoneBased ? (
                <Item hasFeedback={true}>
                  {getFieldDecorator(
                    'phone',
                    validations.phone
                  )(<PhoneInput country={'in'} inputClass="input phone" />)}
                </Item>
              ) : (
                <Item hasFeedback={true}>
                  {getFieldDecorator(
                    'email',
                    validations.email
                  )(
                    <Input
                      placeholder={translate('common.email.placeholder')}
                      className="input"
                    />
                  )}
                </Item>
              )}
              <Item hasFeedback={true}>
                {getFieldDecorator(
                  'password',
                  validations.password
                )(
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder={translate('common.password.placeholder')}
                    suffix={
                      <a
                        onClick={() => setShowPassword(!showPassword)}
                        className="eye-icon"
                      >
                        <Icon type={showPassword ? 'eye-invisible' : 'eye'} />
                      </a>
                    }
                  />
                )}
              </Item>
              <div className="more-links inline">
                <div className="remember">
                  <Item hasFeedback={true}>
                    {getFieldDecorator(
                      'rememberMe',
                      validations.rememberMe
                    )(<Checkbox>{translate('login.rememberme')}</Checkbox>)}
                  </Item>
                </div>
                <div className="forgot">
                  <Link
                    onClick={ui.activeTab ? () => toggleForm('forgot') : null}
                    routeKey={ui.activeTab ? null : 'forgot'}
                  >
                    {translate('login.forgot')}
                  </Link>
                </div>
              </div>
              <div className="action">
                <Button htmlType={'submit'} className="btn primary-btn">
                  {translate('common.login')}
                </Button>
              </div>
            </Form>
          </div>
          {/*<div className="separator">
            <span className="line" />
            <span>OR</span>
            <span className="line" />
          </div>
          <Link routeKey={isPhoneBased ? 'login' : 'login-phone'}>
            {isPhoneBased ? (
              <Button className="btn primary-btn-text">
                <span className="icon phone">
                  <Icon type="mail" />
                </span>
                Log in with email
              </Button>
            ) : (
              <Button className="btn primary-btn-text">
                <span className="icon phone">
                  <Icon type="phone" />
                </span>
                Log in with phone
              </Button>
            )}
          </Link>*/}
        </div>
        <p className="option">
          Don't have an account? <Link routeKey={'signUp'}>Sign Up</Link>
        </p>
      </div>
      <Branding />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    setUiProperty: (data) => {
      return dispatch(createAction(UI_SET_PROPERTY, data));
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ ui }) => {
  return {
    ui,
  };
};

Main.displayName = 'Login-Form';
//Pre process the container with Redux Plugins
export default preProcess(
  Form.create()(
    withLogin(Main, {
      spinningSelector: 'body',
    })
  ),
  {
    connect: [mapStateToProps, bindAction],
    localize: true,
  }
);
