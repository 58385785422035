import React from 'react';
import './styles.scss';
import { Table } from 'antd';

/**
 * @description Forgot Password
 * @type Page
 * @author
 */
const Main = () => {
  const dataSource = [
    {
      key: '1',
      number: 1,
      pattern: 'Low Frequency/Rising ',
      remarks: (
        <div>
          <b>Challenging:</b>
          <p>False Positive Responses: Right Ear 500 and 1000 Hz</p>
          <p>False Positive Responses: Left Ear 500 and 1000 Hz</p>
        </div>
      ),
    },
    {
      key: '2',
      number: 2,
      pattern: 'Asymmetrical Loss',
    },
    {
      key: '3',
      number: 3,
      pattern: 'Noise-Induced Hearing Loss',
    },
    {
      key: '4',
      number: 4,
      pattern: 'High frequency Hearing Loss',
      remarks: (
        <div>
          <b>Challenging:</b>
          <p>False Positive Responses: Right Ear 3000 and 4000 Hz</p>
          <p>False Positive Responses: Left Ear 500 and 8000 Hz</p>
        </div>
      ),
    },
    {
      key: '5',
      number: 5,
      pattern: 'Unilateral Hearing Loss',
      remarks: (
        <div>
          <b>Challenging:</b>
          <p>False Positive Responses: Right Ear 1000 and 3000 Hz</p>
          <p>False Positive Responses: Left Ear 2000 and 4000 Hz</p>
        </div>
      ),
    },
    {
      key: '6',
      number: 6,
      pattern: 'Flat Hearing Loss',
    },
    {
      key: '7',
      number: 7,
      pattern: 'Normal Hearing',
    },
  ];

  const columns = [
    {
      title: 'Employee Number',
      dataIndex: 'number',
      key: 'number',
      width: 200,
    },
    {
      title: 'Pattern of Configuration',
      dataIndex: 'pattern',
      key: 'pattern',
      width: 250,
    }/*,
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    },*/
  ];
  return (
    <div className="references smart-table">
      <p className="title">References</p>
      <div className="table data">
        <Table pagination={false} dataSource={dataSource} columns={columns} />
      </div>
    </div>
  );
};

Main.routeProps = {};
export default Main;
