import React from 'react';
import { Icon, Tooltip } from 'antd';
import Link from '@arivaa-react/components/link';

import DuplicateForm from '../../containers/duplicate-entity';
export default {
  entity: 'page',
  entityName: 'Static Pages',
  formId: 'PageForm',
  search: false,
  pagination: {
    type: 'server',
  },
  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate('staticPage.title.label'),
      dataIndex: 'title',
    },
    {
      title: translate('staticPage.route.label'),
      dataIndex: 'route',
    },
  ],
  rowActions: [
    (props) => {
      const { record, getData } = props;
      const { key } = record;
      return (
        <DuplicateForm
          entityName="page"
          entityId={key}
          propName="route"
          getData={getData}
        />
      );
    },
    (props) => {
      const { record } = props;
      const { route } = record || {};
      return (
        <Tooltip title="Visit">
          <Link
            className="ant-btn btn orange-btn-text visit"
            target="_blank"
            routeKey={'staticPages.' + route}
          >
            <Icon type="eye" />
          </Link>
        </Tooltip>
      );
    },
  ],
};
