import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import { Form, message } from 'antd';
import { getObjectsDiff } from '@arivaa-react/helpers/common';
import { createAction } from '@arivaa-react/redux';
import { CREATE_ENTITY, GET_ENTITIES, UPDATE_ENTITY } from 'app-redux/actions';
import { parsePhone } from '../../../../helpers/phone';

const spinningSelector = '.new-form';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.setValidations();
    this.state = {
      roles: [],
    };
    this.NUM_OF_REFERENCES = Array(3).fill(0);
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { translate, data } = this.props;
    this.validations = {
      name: {
        rules: [
          {
            required: true,
            message: translate('common.firstName.error.required'),
          },
        ],
        initialValue: data ? data.displayName : null,
      },
      email: {
        rules: [
          {
            required: !data && true,
            message: translate('common.email.error.required'),
          },
          { type: 'email', message: translate('common.email.error.invalid') },
        ],
        initialValue: data ? data.email : null,
      },
      password: {
        rules: [
          {
            required: !data && true,
            message: translate('common.password.error.required'),
          },
        ],
      },

      role: {
        rules: [{ required: true, message: translate('user.role.required') }],
        initialValue: data ? (data.customClaims || {}).role : '',
      },
      username: {
        rules: [
          {
            required: true,
            message: translate('common.username.error.required'),
          },
        ],
        initialValue: data?.username,
      },
      phoneNumber: {
        rules: [
          {
            required: false,
            message: translate('common.phone.error.required'),
          },
        ],
        initialValue: data?.phoneNumber, //parsePhone(user?.phoneNumber)?.nationalNumber,
      },
      address: {
        rules: [{ required: true, message: 'Please enter the address' }],
        initialValue: data?.homeAddress?.description,
      },
      bio: {
        initialValue: data?.bio,
      },
      photoURL: {
        initialValue: data?.photoURL,
      },
      alternativePhoneNumber: {
        initialValue: data?.alternativePhoneNumber, //parsePhone(user?.alternativePhoneNumber)?.nationalNumber,
      },
      secondaryPhoneNumber: {
        initialValue: data?.secondaryPhoneNumber, //parsePhone(user?.secondaryPhoneNumber)?.nationalNumber,
      },
      idProof: {
        initialValue: data?.idProof,
      },
      documentProof: {
        initialValue: data?.documentProof,
      },
      homeAddress: {
        initialValue: data?.homeAddress,
      },
    };
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {
    const { getRoles, role } = this.props;
    if (!role) {
      try {
        const { payload, error } = await getRoles();
        if (error) {
          throw payload;
        }
        this.setState({
          roles: payload.data.data,
        });
      } catch (e) {
        console.error('Error while fetching roles', e);
        message.error('Error while fetching roles');
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { validateFieldsAndScroll } = this.props.form;
    const {
      translate,
      createUser,
      getTableData,
      hideModal,
      updateUser,
      data,
      createStorage,
      role,
    } = this.props;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      values = {
        ...values,
      };
      window.startSpinning(spinningSelector);
      try {
        let action;
        // for (let item of [
        //   'phoneNumber',
        //   'secondaryPhoneNumber',
        //   'alternativePhoneNumber',
        // ]) {
        //   if (values[item] && values[item].charAt(0) !== '+') {
        //     values[item] = '+' + values[item];
        //   }
        //   if (!parsePhone(values[item])) {
        //     delete values[item];
        //   }
        // }
        if (values.photoURL instanceof File) {
          try {
            values.photoURL = encodeURI(
              (await createStorage(values.photoURL)).payload.data.url
            );
          } catch (e) {
            console.error('Photo Upload Error ', e);
            message.error(translate('common.changes.save.error'));
            throw e;
          }
        }
        // if (values.idProof instanceof File) {
        //   try {
        //     values.idProof = encodeURI(
        //       (await createStorage(values.idProof)).payload.data.url
        //     );
        //   } catch (e) {
        //     console.error('idProof Upload Error ', e);
        //     message.error(translate('common.changes.save.error'));
        //     throw e;
        //   }
        // }
        // if (values.documentProof instanceof File) {
        //   try {
        //     values.documentProof = encodeURI(
        //       (await createStorage(values.documentProof)).payload.data.url
        //     );
        //   } catch (e) {
        //     console.error('idProof Upload Error ', e);
        //     message.error(translate('common.changes.save.error'));
        //     throw e;
        //   }
        // }
        // const referenceData = [];
        // for (let [index, value] of this.NUM_OF_REFERENCES.entries()) {
        //   let obj = {};
        //   values['name' + index] && (obj.name = values['name' + index]);
        //   values['email' + index] && (obj.name = values['email' + index]);
        //   values['phoneNumber' + index] &&
        //     (obj.name = values['phoneNumber' + index]);
        //   referenceData.push(obj);
        //   delete values['name' + index];
        //   delete values['email' + index];
        //   delete values['phoneNumber' + index];
        // }
        // values.references = referenceData;
        //If image has been changed
        if (data) {
          let updatedValues = getObjectsDiff(values, data);
          action = await updateUser({
            ...updatedValues,
            uid: data.uid,
          });
        } else {
          if (role) {
            values.role = role;
          }
          action = await createUser(values);
        }
        //Update profile

        if (!action.error) {
          message.success(translate('common.changes.save.success'));
          hideModal();
        } else {
          throw action.payload.response;
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
      } catch (e) {
        message.error(
          e.response && e.response.data
            ? e.response.data.message
            : translate('common.changes.save.error')
        );
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    createUser: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'users',
          type: 'local',
          ...data,
        })
      );
    },
    updateUser: (data) => {
      const entityId = data.uid;
      delete data.uid;
      return dispatch(
        createAction(UPDATE_ENTITY, {
          entityName: 'users',
          entityId,
          ...data,
        })
      );
    },
    getRoles: () => {
      const action = createAction(
        GET_ENTITIES,
        {
          entityName: 'roles',
          from: -1,
        },
        true
      );
      return dispatch(action);
    },
    createStorage: (file) => {
      console.log({ file });
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'storage',
          file: file,
          name: file.name,
          type: 'image',
          fileCode: 'userImage',
          createFormData: true,
          optimise: false,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ roles }) => {
  return {};
};
Main.displayName = 'User-Form';
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [null, bindAction],
  localize: true,
});
