import React, { useState } from 'react';
import './styles.scss';
import modelIcon from '../../../images/modal_square.png';
import SmartTable from '@arivaa-react/smart-table';
import { ModalTrigger } from '@arivaa-react/components/modal';
import { Button, Icon, Tooltip, message } from 'antd';
import { isUserAllowed } from 'containers/with-security';
import * as cloneDeep from 'lodash/cloneDeep';
var view = function () {
  const {
    translate,
    actionsWidth,
    entityName,
    entity,
    columns,
    search,
    data,
    selection,
    total,
    modalClass,
    children,
    formProps,
    editAction,
    addAction,
    deleteAction,
    shouldShowActions,
    form,
    refreshAction,
    permissions,
    role,
  } = this.props;

  let { pagination, formId, crudEntityKey, permissionMap } = this.props;
  crudEntityKey = crudEntityKey || entity || '';
  permissionMap = permissionMap || {};
  let { additionalState } = this.state;
  pagination = pagination || {
    type: 'client',
  };
  const addRecord = (props) => {
    return formId ? (
      <ModalTrigger
        modalId={formId}
        modalProps={{
          title: <div>Create</div>,
          className: 'add-record-modal ' + formId + ' ' + modalClass,
          footer: null,
          contentProps: {
            ...formProps,
            getTableData: () => {
              this.getTableData({
                ...this.currentPagination,
                page: 1,
              });
            },
          },
        }}
      >
        <Button className="add">
          <span className="icon">
            <Icon type="plus-circle" theme="outlined" />
          </span>
          {/*<span className="text">{translate('crud.create')}</span>*/}
        </Button>
      </ModalTrigger>
    ) : null;
  };
  const refreshData = (props) => {
    const [loading, setLoading] = useState(false);
    return (
      <Button
        className="add"
        onClick={async () => {
          setLoading(true);
          try {
            await this.getTableData(this.currentPagination);
          } catch (e) {
            console.error('Error while refreshing your data', { e });
            message.error('Error while refreshing your data');
          }
          setLoading(false);
        }}
      >
        <span className="icon">
          <Icon spin={loading} type="redo" theme="outlined" />
        </span>
        {/*<span className="text">{translate('crud.refresh')}</span>*/}
      </Button>
    );
  };
  const editRecord = (props) => {
    return (
      <ModalTrigger
        modalId={formId}
        modalProps={{
          title: <div>Edit</div>,
          className: 'edit-record-modal ' + formId + ' ' + modalClass,
          footer: null,
          contentProps: {
            data:
              form && form.preFormat instanceof Function
                ? form.preFormat(cloneDeep(props.record))
                : cloneDeep(props.record),
            ...formProps,
            getTableData: () => {
              this.getTableData(this.currentPagination);
            },
          },
        }}
      >
        <Tooltip placement="top" title={translate('crud.edit')}>
          <Button className="edit">
            <Icon type="edit" />
          </Button>
        </Tooltip>
      </ModalTrigger>
    );
  };
  let batchActions = [];
  let rowActions = [];
  let headerActions = [];
  if (
    editAction != false &&
    typeof formId == 'string' &&
    (permissionMap && permissionMap.edit
      ? isUserAllowed(
          {
            permissions: [permissionMap.edit],
          },
          {
            permissions,
            role,
          }
        )
      : true)
  ) {
    rowActions.push(editRecord);
  }
  if (
    deleteAction != false &&
    (permissionMap && permissionMap.delete
      ? isUserAllowed(
          {
            permissions: [permissionMap.delete],
          },
          {
            permissions,
            role,
          }
        )
      : true)
  ) {
    rowActions.push('delete');
    batchActions.push('delete');
  }
  if (
    addAction != false &&
    typeof formId == 'string' &&
    (permissionMap && permissionMap.create
      ? isUserAllowed(
          {
            permissions: [permissionMap.create],
          },
          {
            permissions,
            role,
          }
        )
      : true)
  ) {
    headerActions.push(addRecord);
  }

  if (refreshAction !== false) {
    headerActions.push(refreshData);
  }
  rowActions = rowActions.concat(
    (this.props.rowActions || []).map((Action) => {
      return (props2) => (
        <Action
          {...props2}
          additionalState={additionalState}
          getTableData={this.getTableData.bind(this)}
          sharedState={this.props}
        />
      );
    })
  );
  headerActions = headerActions.concat(this.props.headerActions || []);
  let tableColumns = [];
  if (columns instanceof Function) {
    tableColumns = columns({
      sharedState: this.props,
      additionalState,
      getTableData: this.getTableData.bind(this),
    });
  } else {
    tableColumns = columns;
  }
  let searchProps = {};
  if (search instanceof Function) {
    searchProps = search(this.props, additionalState);
  } else {
    searchProps = search;
  }
  let selectionProps;
  if (selection instanceof Function) {
    selectionProps = selection(this.props);
  } else {
    selectionProps = selection;
  }
  return children ? (
    children
  ) : (
    <div className="smart-table">
      <SmartTable
        listConfig={{
          bordered: false,
          dataSource: data,
          columns: (tableColumns || []).map((item) => {
            return {
              ...item,
              className: `${item.className || ''} notranslate`,
            };
          }),
          actions: rowActions,
          onDelete: (record) => {
            return this.deleteRecord(record.key, record);
          },
          actionsWidth,
          selection:
            selectionProps == false ? undefined : { ...selectionProps },
          pagination:
            pagination && pagination.type === 'server'
              ? {
                  total,
                  pageSize: pagination.pageSize || this.pageSize,
                }
              : undefined,
          rowKey: 'key',
          shouldShowActions:
            shouldShowActions instanceof Function
              ? shouldShowActions({
                  sharedState: this.props,
                  additionalState,
                })
              : null,
        }}
        entityName={entityName}
        //displayType = {displayType}
        paginationType={pagination.type}
        getData={this.getTableData.bind(this)}
        headerConfig={{
          actions: headerActions,
          batchActions,
          onDeleteSelected: ({ keys, rows }) => {
            return this.deleteRecord(keys.join(','), rows);
          },
          search: searchProps,
        }}
        title={entityName}
      />
    </div>
  );
};
export default view;
