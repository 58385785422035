import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Form } from 'antd';
import Link from '@arivaa-react/components/link';
import { createAction } from '@arivaa-react/redux';
import { UI_SET_PROPERTY } from 'app-redux/actions';
import withLogin from '../with-login';
import wave from '../../images/beem/auth-form-wave.png';
import back from '../../images/beem/back.png';
import email from '../../images/beem/email.png';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ translate, form }) => {
  return (
    <div className="general-form confirm-email">
      <Link routeKey={'login'} className="back">
        <img src={back} />
      </Link>
      <div className="content">
        <div className="form">
          <div className="fields">
            <p className="title">Check Your Email,</p>
            <p className="description">
              Check your email to have password reset instructions
            </p>
            <div className="graphic">
              <img src={email} className="email" />
            </div>
            <div className="action">
              <Button htmlType={'submit'} className="btn primary-btn">
                Open Mail
              </Button>
            </div>
          </div>
        </div>
        <p className="option">
          Will do it later? <Link>Skip</Link>
        </p>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    setUiProperty: (data) => {
      return dispatch(createAction(UI_SET_PROPERTY, data));
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ ui }) => {
  return {
    ui,
  };
};

Main.displayName = 'OTP-Verification-Form';
//Pre process the container with Redux Plugins
export default preProcess(
  Form.create()(
    withLogin(Main, {
      spinningSelector: 'body',
    })
  ),
  {
    connect: [mapStateToProps, bindAction],
    localize: true,
  }
);
